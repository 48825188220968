.news-element-card {
    width: 401px;
    height: 224px;
    font-family: 'Montserrat', sans-serif;

    background: #fff;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    transition: all .5s;
    cursor: pointer;
    margin-right: 1.7%;
}

.news-element-card:nth-child(3n) {
    margin-right: 0;
}

@media (max-width: 1280px) {
    .news-element-card:nth-child(3n) {
        margin-right: 1.7%;
    }

    .news-element-card:nth-child(2n) {
        margin-right: 0;
    }
}

@media (max-width: 860px) {
    .news-element-card {
        margin-right: 0;
    }
}

.news-element-card a {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.news-element-card:hover {
    border-color: #0273BA;
}

.news-card-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-top: 0;
}

.news-card-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #000000;
}

.news-element-card time {
    margin-top: auto;

    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: rgba(2, 115, 186, .48);

}

@media (max-width: 640px) {
    .news-element-card {
        width: 100%;
        height: 180px;
    }

    .news-card-title {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 20px;
    }

    .news-card-text {
        font-size: 12px;
        line-height: 20px;
    }

    .news-element-card time {
        font-size: 10px;
        line-height: 16px;
    }
}