.about-section1 {
  font-family: 'Montserrat', sans-serif;
  /* background: url(../../assets/img/чап.svg) 1% 7.4% repeat-y,
        url(../../assets/img/рост.svg) 99% 7.4% repeat-y; */
  /*padding: 100px 0 130px;*/
  font-size: 18px;
  background-size: 83px;
  margin-top: 40px;
}

.about__wrapper {
  max-width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
}

.about-block {
  margin-bottom: 80px;
}

.about-block:last-of-type {
  margin-bottom: 0;
}

.about-title {
  font-weight: 700;
  font-size: 34px;
  line-height: 50px;
  letter-spacing: -0.01em;
  color: #080B11;
  margin-bottom: 40px;
  margin-top: 0 !important;
}

.about-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: #000000;
}

.about-left {
  margin-right: 70px;
}

.about-wrapper {
  display: flex;
  justify-content: space-between;
}

.about-breadcrumb {
  text-align: right;
  text-transform: lowercase;
  margin-top: 0;
  margin-bottom: 80px;
}

.about-breadcrumb a {
  color: #000;
}

.about-author-link {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #0273BA;
  display: block;
  margin-top: 30px;
}

.about__text .about__text_span {
  display: block;
  margin-top: 24px;
}

.about-wrapper_center {
  align-items: center;
}

.about-wrapper__inner_flex {
  display: flex;
}

.about__text_margin {
  margin-bottom: 22px;
}

.about__img {
  width: 410px;
  height: 416px;
  border-radius: 4px;
}

/*.about__logo-svg {*/
/*  margin-top: -4rem;*/
/*  width: 277px;*/
/*  max-width: 100%;*/
/*  float: right;*/
/*  padding: 0 72px 38px 46px;*/
/*}*/

.about-author-img-wrapper {
  margin-right: 40px;
}

.about-wrapper-text {
  display: flex;
}

.about__text_1 {
  margin: 50px 0 60px;
}

.about__text_bold {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 18px;
  color: #000000;
  margin-top: 30px;
}

.title-bread-crumb {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  letter-spacing: -1px;
  text-transform: uppercase;
  color: #000000;
}

.text-bread-crumb {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.5px;
  color: #2b2b2b;
}

.pc__about-wrapper {
  display: block;
  display: flex;
}

.mobile__about-wrapper {
  display: none;
}

.about__text a {
  text-decoration: none !important;
}

@media (max-width: 1024px) {

  .about-left {
    margin-right: 0;
  }

  .about-wrapper__inner_flex {
    flex-wrap: wrap;
    justify-content: center;
  }

  .about-author-img-wrapper {
    order: 1;
    margin: 20px 0;
  }

  .about-author-img {
    width: 350px;
    order: 2;
  }

  .about__logo-svg {
    margin: 40px 0 0;
  }
}

@media (max-width: 640px) {

  .about-breadcrumb {
    display: none;
  }

  .about-text {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: rgba(8, 11, 17, .65);
    text-align: justify;
  }

  .about-title {
    font-size: 24px;
    line-height: 29px;
    margin: 20px 0;
  }

  .about__logo-svg {
    width: 327px;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1920px) {
  .about-section1 {
    background-size: 180px;
  }

  .container {
    max-width: 1400px !important;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1440px) {
  .about-section1 {
    background-size: 130px;
  }

  .container {
    max-width: 1040px !important;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1366px) {

  .about-section1 {
    background-size: 110px;
  }

  .container {
    max-width: 1000px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .about-section1 {
    background-size: 100px;
  }

  .container {
    max-width: 960px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1170px) {
  .about-section1 {
    background-size: 90px;
  }

  .container {
    max-width: 800px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
  .about-section1 {
    background: none;
  }

  .container {
    /*max-width: auto;*/
  }

  .pc__about-wrapper {
    /*display: none;*/
  }

  .about-breadcrumb {
    display: none;
  }

  .mobile__about-wrapper, .mobile__about-wrapper .about-breadcrumb {
    display: block;
    margin-top: 25px;
  }
}

@media (max-width: 768px) {

  .title-bread-crumb {
    font-size: 26px;
    line-height: 45px;
    order: 1;
  }

  .text-bread-crumb {
    order: 2;
    font-size: 22px;
    margin-bottom: 10px;
  }

  .about__text_1 {
    margin: 20px 0;
  }

  .pc__about-wrapper {
    display: none;
  }

  .mobile__about-wrapper {
    display: block;
    margin-top: 25px;
  }
}

@media (max-width: 425px) {
  .about__img {
    float: none;
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .about-wrapper__inner_flex {
    align-items: end;
  }

  .pc__about-wrapper {
    display: none;
  }

  .mobile__about-wrapper {
    display: block;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
