.about-section {
  /* background: url(../../assets/img/чап.svg) 1% 3.1% repeat-y,
        url(../../assets/img/рост.svg) 99% 3.1% repeat-y; */
  padding: 100px 0 140px;
  font-size: 18px;
  background-size: 100px;
}

.info_wrapper {
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 28px;
  height: 100%;
}

.info_wrapper_2 {
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 400px;
}

.info_wrapper_2 iframe {
  /*height: 100%;*/
}

.info__text p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;

  color: #000000;
}

.info__phone-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: #000000;
}

.info__phone-text a {
  text-decoration: none !important;
  color: #000000 !important;
  transition: 0.5s ease-in-out;
}
.info__phone-text a:hover {
  transition: 0.5s ease-in-out;
  color: #0056b3 !important;
}

.info__address-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: #000000;
  display: flex;
}

.info__address-text a {
  text-decoration: none !important;
  color: #000000 !important;
}
.info__address-text a:hover {
  transition: 0.5s ease-in-out;
  color: #0056b3 !important;
}
.info__contact {
  margin: 0 !important;
  padding: 0 !important;
}

.info__contact li {
  display: flex;
  list-style: none;
}

.info__img {
  padding: 0px 10px 13px 0px;
}

.img__adress {
  margin-left: -7px;
}

.contact__map {
  width: 100% !important;
  border-radius: 15px !important;
}

@media screen and (min-width: 1440px) and (max-width: 1920px) {
  .about-section {
    /* background: url(../../assets/img/чап.svg) 1% 17% repeat-y,
            url(../../assets/img/рост.svg) 99% 17% repeat-y; */
    background-size: 170px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1366px) {
  .about-section {
    /* background: url(../../assets/img/чап.svg) 1% 16% repeat-y,
            url(../../assets/img/рост.svg) 99% 16% repeat-y; */
    background-size: 130px;
  }
}

@media screen and (min-width: 1180px) and (max-width: 1280px) {
  .about-section {
    /* background: url(../../assets/img/чап.svg) 1% 16% repeat-y,
            url(../../assets/img/рост.svg) 99% 16% repeat-y; */
    background-size: 90px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .info__phone-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
  }

  .info__address-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    display: flex;
  }

  .about-section {
    background: none;
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .info_wrapper {
    height: 456px;
    padding: 20px;
    margin-top: 29px;
    margin-bottom: 20px;
  }

  /* .map__about {
        height: 600px !important;
    } */

  .info__phone-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
  }

  .info__address-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    display: flex;
  }

  .map__about {
    margin-top: 2rem;
  }

  .about-section {
    background: none;
    padding-bottom: 0 !important;
  }
}
