.career-section {
}

.jobs-elements {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.notf-section {
    /* background: url(../../assets/img/чап.svg) 1% 3.1% repeat-y,
        url(../../assets/img/рост.svg) 99% 3.1% repeat-y; */
    padding: 90px 0 10px;
    font-size: 18px;
    background-size: 100px;
}


.ofices-card {
    padding: 12px;
    max-width: 360px;
    min-height: 480px;
    background: #FFFFFF;
    box-shadow: 0 0 10px 3px rgb(0 0 0 / 25%);
    border-radius: 20px;
}

.card__img {
    max-width: 350px;
    height: 200px;
}

.card__img a {
    text-decoration: none !important;
}

.card__img img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    cursor: pointer;
}

.card__title-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 56px;
    letter-spacing: -1px;
    color: #000000;
    cursor: pointer;
}

.card__title-text a {
    text-decoration: none !important;
    color: #000000 !important;
}

.card__text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #2B2B2B;
}

.card__text a {
    text-decoration: none !important;
    color: #2B2B2B !important;
}


@media screen and (min-width: 1440px) and (max-width: 1920px) {
    .career-section {
        /* background: url(../../assets/img/чап.svg) 1% 17% repeat-y,
            url(../../assets/img/рост.svg) 99% 17% repeat-y; */
        background-size: 170px;
    }

}

@media screen and (min-width: 1280px) and (max-width: 1366px) {
    .career-section {
        /* background: url(../../assets/img/чап.svg) 1% 16% repeat-y,
            url(../../assets/img/рост.svg) 99% 16% repeat-y; */
        background-size: 130px;
    }

}

@media screen and (min-width: 1180px) and (max-width: 1280px) {
    .career-section {
        /* background: url(../../assets/img/чап.svg) 1% 16% repeat-y,
            url(../../assets/img/рост.svg) 99% 16% repeat-y; */
        background-size: 90px;
    }

}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .career-section {
        background: none;
        padding-bottom: 0 !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
    .ofices-card {
        margin: 14px;
    }

    .career-section {
        background: none;
        padding-bottom: 0 !important;
    }
}

@media (min-width: 1245px) {
    .jobs-elements {
        justify-content: flex-start;
    }

    .jobs-elements > div {
        margin-right: 20px !important;
    }

    .jobs-elements > div:nth-of-type(3n) {
        margin-right: 0 !important;
    }
}

@media (max-width: 1245px) {
    .career-section {
        max-width: 840px !important;
    }
}

@media (max-width: 840px) {
    .jobs-elements {
        justify-content: center;
    }
    .career-section {
        max-width: 422px !important;
    }
}

@media (max-width: 640px) {
    .career-section {
        max-width: 360px !important;
    }
}