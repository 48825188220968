.button-more {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 20px;
    color: #0273BA;
    border-radius: 4px;
    border: none;
    background: none;
    transition: all .5s;
    margin-left: auto;
    display: block;
    margin-top: 20px;
    padding: 10px 30px;
}

.button-more a, .button-more a:hover {
    color: #0273BA;
    text-decoration: none;
}

.button-more:hover {
    background: rgba(2, 115, 186, .1);
}

@media (max-width: 640px) {
    .button-more {
        display: none;
    }
}