.sales-card {
    width: 392px;
    border-radius: 4px;
    overflow: hidden;
    /*border: 1px solid #EDEDED;*/
    /*box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);*/
    font-family: 'Montserrat', sans-serif;
    height: 100%;
    box-sizing: border-box;
    min-height: 424px;
    transition: all .5s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.sales-card:hover {
    box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.2);
}

.sales-address {
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    margin: 0 0 8px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, .48);
    height: 16px;
}

.sales-name {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.01em;
    color: #080B11;
    margin: 8px 0;
}

.sales-img {
    max-width: 392px;
    height: 220px;
}

.sales-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
}

.sales-info {
    padding: 20px;
    border: 1px solid #EDEDED;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    flex-grow: 1;
}

.sales-info-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sales-info-list li {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: rgba(0, 0, 0, .65);
    margin: 8px 0;
}

.sales-subtitle {
    color: #0273BA;
}

.sales-info-list a {
    color: rgba(0, 0, 0, .65);
}


@media (max-width: 640px) {

    .sales-card {
        width: 328px;
    }

    .sales-info {
        padding: 12px;
    }

    .sales-name {
        font-size: 20px;
        line-height: 24px;
        margin: 8px 0;
    }
}