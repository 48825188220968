.team-section {
    padding: 100px 0;
}

.team-card {
    margin-top: 25px;
    margin-bottom: 55px;
    padding: 10px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 4px rgb(0 0 0 / 10%);
    border-radius: 20px;
}


.team-card__img img {
    max-width: 100%;
    min-height: 250px;
    object-fit: cover;
    border-radius: 12px;
}

.team-name {
    margin: 20px 0 0 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -1px;

    color: #000000;
}

.team-desc {
    margin-bottom: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.5px;

    color: #2B2B2B;

}