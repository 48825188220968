.promotions-section {
    /* display: none; */
    width: 100%;
    position: relative;
    padding: 85px 0 0;
}

.promotions__wrapper {
    /* display: none; */
    padding: 30px 70px;
    width: 100%;
    height: auto;
    background: #0273ba;
    border-radius: 27px;
}

.sale_none {
    display: none !important;
}

.pro__wrapper-title {
    margin: 0 !important;
}

.pro__wrapper-title p {
    margin: 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 46px;
    letter-spacing: -1px;
    color: #ffffff;
}

.pro__wrapper-title span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: -1px;
    color: #ffffff;
}

.pro__wr-text p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 46px;
    letter-spacing: -1px;

    color: #ffffff;
}

.icon__promotions {
    position: absolute;
    left: -15px;
    bottom: -5px;
}

.promo__days p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 80px;
    line-height: 80px;
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    /* padding-top: 10px; */
    /* White Color Armon */
    margin: 0 !important;
    color: #ffffff;
}

.promo__days span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 49px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -1px;

    /* White Color Armon */

    color: #ffffff;
}

.icon__promotions img {
    width: 398px;
}

.btn__promo {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.btn__promo button {
    background: #0273ba;
    border-radius: 27px;
    outline: none;
    border: 2px solid #fff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -1px;
    color: #ffffff;
    padding: 5px 20px 5px 20px;
    transition: 0.5s ease-in-out;
}

.btn__promo button:hover {
    transition: 0.5s ease-in-out;
    background: #ff0000;
}

/* start modal */

.promo__modal .rodal-fade-enter .rodal-dialog {
    background: #0273ba;
    border-radius: 27px;
    width: 520px !important;
    height: 715px !important;
    padding: 35px !important;
}

.modal__input__group .toast.show {
    position: absolute;
    bottom: 344px;
    display: block;
    opacity: 1;
}

.modal__input__group .toast.show .toast-body {
    padding: 0.5rem;
}

.modal__input__group .toast {
    background-color: #fff !important;
}

.toasp__text-modal p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -1px;
    margin: 0 !important;
    padding: 0 !important;
    color: rgb(109, 109, 109);
}

.promo__modal .rodal-fade-enter .rodal-dialog .rodal-close {
    border-radius: 50% !important;
    top: 20px !important;
    right: 20px !important;
    width: 30px !important;
    height: 30px !important;
    border: 2px solid #fff !important;
}

.promo__modal .rodal-fade-enter .rodal-dialog .rodal-close:before {
    border-radius: 0 !important;
    background: #fff !important;
    width: 60% !important;
    left: 5px !important;
}

.promo__modal .rodal-fade-enter .rodal-dialog {
    border-radius: 16px !important;
    animation-duration: 300ms;
}

.promo__modal .rodal-fade-enter .rodal-dialog .rodal-close:after {
    border-radius: 0 !important;
    background: #fff !important;
    width: 60% !important;
    left: 5px !important;
}

.modal-icon__promotions {
    display: flex;
    justify-content: center;
}

.modal-icon__promotions img {
    position: absolute;
    width: 320px;
    bottom: 490px;
}

.stock__wrapper {
    margin-top: 185px;
}

.stock__wrapper-title p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 31px;
    line-height: 33px;
    text-align: center;
    letter-spacing: -1px;

    /* White Color Armon */

    color: #ffffff;
}

.pdtext {
    display: flex;
    justify-content: center;
    width: 100%;
}

.ddtt {
    width: 80px;
    height: 3px;
    background: #ffffff;
    border-radius: 15px;
    margin-bottom: 15px;
}

.stock__wrapper-text p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: -1px;

    /* White Color Armon */

    color: #ffffff;
}

.modal__input__group ul {
    padding: 0 !important;
}

.modal__input__group ul li {
    list-style: none;
    display: flex;
    justify-content: center;
}

.btn__stock {
    display: flex;
    justify-content: center;
}

.modal__input__group .mdl__input {
    background: #ffffff;
    border-radius: 29px;
    outline: none;
    border: none;
    height: 40px;
    width: 60%;
    padding-left: 15px;
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .promo__modal .rodal-fade-enter .rodal-dialog {
        background: #0273ba;
        border-radius: 27px;
        height: 615px !important;
        padding-right: 15px !important;
    }

    .stock__wrapper {
        margin-top: 100px;
    }

    .modal-icon__promotions img {
        position: absolute;
        width: 173px;
        bottom: 473px;
    }
}

/* and modal */
@media (max-width: 1024px) {
    .icon__promotions {
        position: absolute;
        left: 14px;
        bottom: 85px;
    }

    .icon__promotions img {
        width: 280px;
    }

    .promo__days p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 65px;
        line-height: 40px;
        display: flex;
        align-items: center;
        letter-spacing: -1px;
        padding-top: 10px;
        margin: 0 !important;
        color: #ffffff;
    }

    /* .icon__promotions {
            display: flex;
            justify-content: center;
            align-items: center;
        }


        .promotions__wrapper {
            padding: 25px 30px;
        }

        .promo__days p {
            display: flex;
            justify-content: center;
        }

        .pro__wrapper-title p {
            display: flex;
            justify-content: flex-end;
            text-align: end;
        }

        .pro__wrapper-title span {
            display: flex;
            justify-content: flex-end;
            font-size: 15px;
            text-align: end;
        }

        .pro__wr-text p {
            font-size: 22px;
            display: flex;
            text-align: center;
            justify-content: center;
        }

        .promo__days p {
            font-size: 42px;
            line-height: 10px;
        }

        .promo__days span {
            font-size: 22px;
        }

        .pro__wrapper-title {
            padding-top: 170px;
        }

        .promo__time {
            margin-left: 15px;
        }

        .promo__modal .rodal-fade-enter .rodal-dialog {
            background: #0273BA;
            border-radius: 27px;
            width: 95% !important;
            height: 715px !important;
            padding: 15px !important;
        }

        .stock__wrapper-title p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 49px;
            text-align: center;
            letter-spacing: -1px;
            color: #FFFFFF;
        }

        .stock__wrapper-text p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 34px;
            letter-spacing: -1px;
            color: #FFFFFF;
        } */
}

@media screen and (min-width: 490px) and (max-width: 768px) {
    .icon__promotions {
        position: absolute;
        left: 14px;
        bottom: 85px;
    }

    .icon__promotions img {
        width: 280px;
    }

    .promo__days p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 45px;
        line-height: 40px;
        display: flex;
        align-items: center;
        letter-spacing: -1px;
        padding-top: 10px;
        margin: 0 !important;
        color: #ffffff;
    }
}

@media screen and (min-width: 428px) and (max-width: 480px) {
    .promotions__wrapper {
        padding: 25px 30px;
    }

    .promo__days p {
        display: flex;
        justify-content: center;
    }

    .pro__wrapper-title p {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .pro__wrapper-title span {
        display: flex;
        font-size: 15px;
        text-align: center;
    }

    .pro__wr-text p {
        font-size: 22px;
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .promo__days p {
        font-size: 42px;
        line-height: 10px;
    }

    .promo__days span {
        font-size: 22px;
    }

    .icon__promotions {
        position: absolute;
        left: 60px;
        bottom: 210px;
    }

    .icon__promotions img {
        width: 280px;
    }

    .pro__wrapper-title {
        padding-top: 170px;
    }

    .promo__time {
        margin-left: 15px;
    }

    .promo__modal .rodal-fade-enter .rodal-dialog {
        background: #0273ba;
        border-radius: 27px;
        width: 95% !important;
        height: 715px !important;
        padding: 15px !important;
    }

    .stock__wrapper-title p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 49px;
        text-align: center;
        letter-spacing: -1px;
        color: #ffffff;
    }

    .stock__wrapper-text p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 34px;
        letter-spacing: -1px;
        color: #ffffff;
    }
}

@media screen and (min-width: 405px) and (max-width: 427px) {
    .promo__modal .rodal-fade-enter .rodal-dialog {
        background: #0273ba;
        border-radius: 27px;
        width: 95% !important;
        height: 715px !important;
        padding: 15px !important;
    }

    .stock__wrapper-title p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -1px;
        color: #ffffff;
    }

    .stock__wrapper-text p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 34px;
        letter-spacing: -1px;
        color: #ffffff;
    }

    .promotions__wrapper {
        padding: 25px 30px;
    }

    .promo__days p {
        display: flex;
        justify-content: center;
    }

    .pro__wrapper-title p {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .pro__wrapper-title span {
        display: flex;
        font-size: 15px;
        text-align: center;
    }

    .pro__wr-text p {
        font-size: 22px;
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .promo__days p {
        font-size: 38px;
        line-height: 10px;
    }

    .promo__days span {
        font-size: 16px;
    }

    .icon__promotions {
        position: absolute;
        left: 66px;
        bottom: 370px;
    }

    .icon__promotions img {
        width: 280px;
    }

    .pro__wrapper-title {
        padding-top: 180px;
    }

    .promo__time {
        margin-left: 20px;
    }

    .btn__promo {
        margin-top: 10px;
    }
}

@media screen and (min-width: 320px) and (max-width: 400px) {
    .promo__modal .rodal-fade-enter .rodal-dialog {
        background: #0273ba;
        border-radius: 27px;
        width: 95% !important;
        height: 715px !important;
        padding: 15px !important;
    }

    .stock__wrapper-title p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 49px;
        text-align: center;
        letter-spacing: -1px;
        color: #ffffff;
    }

    .stock__wrapper-text p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 34px;
        letter-spacing: -1px;
        color: #ffffff;
    }

    .promotions__wrapper {
        padding: 25px 30px;
    }

    .promo__days p {
        display: flex;
        justify-content: center;
    }

    .pro__wrapper-title p {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .pro__wrapper-title span {
        display: flex;
        font-size: 15px;
        text-align: center;
    }

    .pro__wr-text p {
        font-size: 22px;
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .promo__days p {
        font-size: 38px;
        line-height: 10px;
    }

    .promo__days span {
        font-size: 16px;
    }

    .icon__promotions {
        position: absolute;
        left: 45px;
        bottom: 292px;
    }

    .icon__promotions img {
        width: 280px;
    }

    .pro__wrapper-title {
        padding-top: 180px;
    }

    .promo__time {
        margin-left: 20px;
    }

    .btn__promo {
        margin-top: 10px;
    }
}
