.sales-icon {
  margin: 0 5px 0 0;
}

.layouts-elements {
  justify-content: space-between;
  margin: 0 15px;
}

.sales-section .page-top {
  margin-left: 17px;
  margin-right: 17px;
}

.sales-section .page-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  letter-spacing: -1px;
  text-transform: uppercase;
  color: #000000;
}

.sales-wrapper-inner {
  margin-bottom: 2px;
}

.sales-filter {
  margin: 20px 17px;
}

.sales-filter-list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.sales-filter-list li {
  max-width: 300px;
  margin-right: 12px;
  margin-bottom: 20px;
  flex-grow: 1;
}

@media (max-width: 860px) {
  .sales-filter-list {
    justify-content: center;
  }

  .sales-filter-list li {
    /*width: 100%;*/
  }
}

.sales-filter-list button {
  width: 100%;
}

.firstp {
  display: none;
}

.sales-image {
  /* width: 100%;
  height: 100%; */
  object-fit: contain;
  /* max-width: 100%;
  min-height: 150px; */
  /*border: 2px solid #e6e6e6;*/
  box-sizing: border-box;
  /*border-radius: 12px;*/
  /* transform: rotateX(45deg); */
}

.sales-button {
  margin-top: auto;
  padding: 0px 24px;
  width: 100%;
  height: 44px;
  background: #0273BA;
  border-radius: 2px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #fff;
  transition: all .5s;
  border: none;
}

.sales-button:hover {
  background: #075D93;
}


.sales-card-info {
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.nosales {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btns__filter:first-child {
  margin-left: 0 !important;
}

.btns__filter:hover {
  border-color: #2e67c4;
}

.wraper-modal-1 .toast {
  position: absolute !important;
  top: 225px !important;
  right: 35px !important;
  max-width: 345px !important;
}

.btns__filter {
  padding: 0 10px;
  height: 42px;
  background: #ffffff;
  border: 2px solid #E9E9E9;
  box-sizing: border-box;
  border-radius: 4px;
  width: 25%;
  margin-left: 2rem;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 29px;
  letter-spacing: -1px;
  text-transform: capitalize;
  transition: all .5s;
  color: #000000;
}

.btn__filter {
  outline: none !important;
  border: none;
  height: 40px;
  background: #0273ba;
  border-radius: 12px;
  /* padding: 0 75px; */
  width: 25%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -1px;
  text-transform: capitalize;
  color: #ffffff;
}

.layouts__card {
  padding: 0;
  width: 392px;
  max-height: 471px;
  background: #fff;
  margin-bottom: 40px;
  border: 1px solid #EDEDED;
}

.layouts__card:nth-of-type(3n) {
margin-right: 0;
}

.layouts__img img {
max-width: 100%;
}

.floor, .area {
font-family: 'Montserrat', sans-serif;
font-weight: 600;
font-size: 16px;
line-height: 26px;
letter-spacing: -0.01em;
color: rgba(8, 11, 17, .48);
}

.room {
font-family: 'Montserrat', sans-serif;
font-weight: 700;
font-size: 10px;
line-height: 16px;
letter-spacing: 1.5px;
text-transform: uppercase;
color: rgba(2, 115, 186, .48);
margin: 0;
}

.img__hover {
position: relative;
cursor: pointer;
margin-bottom: 10px;
max-width: 392px;
max-height: 248px;
}

.img__hover .icon-h {
position: absolute;
transform: translate(-50%, -50%);
top: 50%;
left: 50%;
opacity: 0;
}
.sales-img-wrapper {
width: 100%;
height: 100%;
}
.img__hover:hover .sales-img-wrapper {
transition: all 0.5s ease;
/* width: 100%;
height: 100%; */
transition-delay: 0.2s;
border: none;
filter: brightness(65%);
}

.text__w {
font-family: Montserrat, sans-serif;
font-weight: 700;
font-size: 24px;
line-height: 35px;
letter-spacing: -0.01em;
color: #080B11;
margin: 8px 0 20px;
}

.img__hover:hover .icon-h {
transition: all 0.5s ease;
transition-delay: 0.2s;
opacity: 1;
}

.layouts__card .video-container {
width: 100%;
margin: 0;
}

.btn__h-effects {
position: absolute;
right: 27px;
bottom: 10px;
outline: none;
border: none;
background: #0273ba;
border-radius: 50px;
width: 40px;
height: 40px;
padding: 5px;
z-index: 9;
display: flex;
justify-content: center;
align-items: center;
}

.btn__h-effects:hover {
transition: all 0.5s ease;
transition-delay: 0.2s;
border-radius: 50px;
width: 80%;
z-index: 9;
}

.btn__h-effects:hover p {
transition: all 0.6s ease;
transition-delay: 0.3s;
opacity: 1;
}

.btn__h-effects:hover img {
}

.btn__h-effects {
line-height: 0;
}

.btn__h-effects .icon img {
position: absolute;
bottom: 11px;
right: 12px;
z-index: 99;
}

.btn__h-effects p {
opacity: 0;
font-family: Montserrat;
font-weight: 600;
font-size: 16px;
color: #ffffff;
margin-right: 23px;
/* padding: 15px 0; */
}

.filter__layouts {
  width: 100%;
  display: flex;
}

/* //////////////       modal form */

.layouts-modal__wrapper .modal .modal-dialog .modal-content {
  border-radius: 8px !important;
  background-color: #2e67c4 !important;
  outline: none !important;
}

/* and modal form */

/* ///// modal img */

.rodal-dialog {
  border-radius: 8px !important;
  animation-duration: 300ms;
}

.layouts-modal__img {
  max-width: 100%;
}

.TransformComponent-module_container__3NwNd {
  border-radius: 16px !important;
  width: 100% !important;
  height: 100% !important;
}

.tools {
  position: absolute;
  padding: 10px;
  z-index: 9;
}

.tools .tol__button {
  border-radius: 50%;
  border: none;
  font-weight: bold;
  outline: none;
  margin-right: 5px;
  width: 35px;
  height: 35px;
  background: #2f8cca;
}

.rodal-dialog {
  padding: 0 !important;
  width: 1100px !important;
  height: 515px !important;
}

.layouts-modal__wrapper .rodal-fade-enter .rodal-dialog .rodal-close:before,
.rodal-close:after {
  border-radius: 0 !important;
  background: #000 !important;
}

/* form modal */
.layouts-form__modal__wrapper
  .rodal-fade-enter
  .rodal-dialog
  .rodal-close:before {
  border-radius: 0 !important;
  background: #fff !important;
  width: 60% !important;
  left: 5px !important;
}

.layouts-form__modal__wrapper .rodal-fade-enter .rodal-dialog {
  border-radius: 16px !important;
  animation-duration: 300ms;
}

.layouts-form__modal__wrapper
  .rodal-fade-enter
  .rodal-dialog
  .rodal-close:after {
  border-radius: 0 !important;
  background: #fff !important;
  width: 60% !important;
  left: 5px !important;
}

.layouts-form__modal__wrapper .form__group {
  width: 100%;
  height: 100%;
  background: #6296cf;
  border-radius: 16px;
  padding: 55px 35px;
}

.layouts-form__modal__wrapper .form__group ul {
  padding: 0;
}

.wraper-modal-1 {
  margin: 32px;
}

.wraper-modal-1 img {
  width: 620px;
  border: 2px solid #e6e6e6;
  border-radius: 16px;
}

.wraper-modal-1 h3 {
  margin-bottom: 27px;
}

.sales-wrapper-inner p {
  margin: 0;
}

.sales-wrapper-inner:first-of-type {
  margin-right: 40px;
}

.sales-wrapper-inner .sales-icon2 {
  margin: 0 35px 0 0 !important;
  width: 32px;
}

.wraper-modal-2 {
  max-width: 415px;
  height: 515px;
}

.layouts-form__modal__wrapper .form__group .input__group {
  margin-top: 50px;
}

.midle-input {
  margin-top: 20px;
}

.layouts-form__modal__wrapper .form__group .input__group .btn__form-group {
  margin-top: 40px;
  width: 150px;
  border: none;
  outline: none;
  height: 40px;
  background: #383d45;
  border-radius: 9px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;

  color: #ffffff;
}

.layouts-form__modal__wrapper .form__group .input__group input {
  outline: none !important;
  padding-left: 35px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
}
.activeBtn {
  background: #2e67c4;
  border: 1px solid #0273ba;
  color: #fff;
}

.layouts-form__modal__wrapper .rodal-fade-enter .rodal-dialog .rodal-close {
  border-radius: 50% !important;
  top: 20px !important;
  right: 20px !important;
  width: 30px !important;
  height: 30px !important;
  border: 2px solid #fff !important;
}

/*  */
.layouts-modal__wrapper .rodal-fade-enter .rodal-dialog .rodal-close {
  border-radius: 50% !important;
  top: 20px !important;
  right: 20px !important;
  width: 30px !important;
  height: 30px !important;
  border: 2px solid #000 !important;
}

.layouts-modal__wrapper .rodal-fade-enter .rodal-dialog .rodal-close:after {
  border-radius: 0 !important;
  background: #000 !important;
  width: 60% !important;
  left: 5px !important;
}

.layouts-modal__wrapper .rodal-fade-enter .rodal-dialog .rodal-close:before {
  border-radius: 0 !important;
  background: #000 !important;
  width: 60% !important;
  left: 5px !important;
}

.list__t li {
  list-style: none;
  margin-bottom: 26px;
}

.list__t li p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;

  color: #ffffff;
}

.img__group {
}

.img__jk img {
  max-width: 100%;
  min-height: 150px;
  border: 3px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 12px;
}

.title__jk {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  text-transform: uppercase;

  color: #000000;
}

.jk__form {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -1px;
  color: #ffffff;
  top: 460px;
  position: absolute;
  left: 885px;
  height: 40px;
  background: #0273ba;
  border-radius: 94px;
  width: 200px;
  border: none;
}

.wraper-modal-1 h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  text-transform: uppercase;

  color: #000000;
}

/* and form modal */

/* .tools button svg {
    fill: #FFFFFF !important;
} */
.img__trasformw {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.nosales {
  width: 100%;
  height: 400px;
}
.nosales h2 {
}

@media (max-width: 1280px) {
  .sales-section .page-top {
    /*max-width: 820px;*/
    /*margin: 40px auto;*/
    padding: 0 25px;
  }

  .sales-filter {
    max-width: 820px;
    margin: 20px auto;
    /*padding: 0 25px;*/
    /*margin-left: 12px;*/
    /*margin-right: 12px;*/
  }

  .layouts-elements {
    max-width: 820px;
    margin: 0 auto;
  }
}

@media (max-width: 1024px) {

  /*.sales-section .page-top {*/
  /*  margin: 40px 10%;*/
  /*}*/

  /*.sales-filter-list {*/
  /*  margin: 0 10%;*/
  /*}*/

  /*.layouts-elements {*/
  /*  justify-content: center;*/
  /*  !*max-width: 600px;*!*/
  /*}*/

  /*.layouts__card {*/
  /*  flex-basis: 38%;*/
  /*  margin-right: 4%;*/
  /*}*/

  /*.layouts__card:nth-of-type(3n) {*/
  /*  margin-right: 4%;*/
  /*}*/

  /*.layouts__card:nth-of-type(2n) {*/
  /*  margin-right: 0;*/
  /*}*/
}

@media (max-width: 940px) {
  .sales-filter {
  }
}

@media (max-width: 840px) {
  .layouts-elements {
    max-width: 392px;
    justify-content: center;
  }

  .sales-filter {
    max-width: 392px;
    margin: 0 auto;
    padding: 0;
  }

  .sales-filter-list li {
    flex-basis: 100%;
    max-width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 768px) {

  .sales-section .page-top {
    margin: 20px auto;
    max-width: 392px;
    padding: 0;
  }

  /*.sales-filter {*/
  /*  max-width: 392px;*/
  /*  margin: 0 auto;*/
  /*  padding: 0;*/
  /*}*/

  /*.sales-filter-list li {*/
  /*  flex-basis: 100%;*/
  /*  max-width: 100%;*/
  /*  margin-right: 0;*/
  /*}*/


  .layouts-elements {
    max-width: 400px;
    margin: 0 auto;
  }

  .layouts__card {
    /*max-width: 100%;*/
    /*flex-basis: 100%;*/
    margin-right: 0;
  }
}

@media (max-width: 620px) {

  .sales-section .page-top {
    margin: 40px auto 20px;
  }

  .sales-section .page-title {
    font-size: 24px;
    line-height: 29px;
  }

  .sales-filter {
    max-width: 328px;
  }

  .sales-filter-list button {
    font-size: 12px;
    line-height: 20px;
  }

  .sales-section .page-top {
    max-width: 328px;
  }

  .sales-section .sales-card {
    max-width: 328px;
  }

  .text__w {
    font-size: 20px;
    line-height: 24px;
  }

  .sales-subinfo {
    justify-content: space-between;
  }

  .img__hover {
    max-height: 209px;
  }
}

@media screen and (min-width: 1700px) and (max-width: 1920px) {
  .layouts-form__modal__wrapper .rodal-fade-enter .rodal-dialog {
    width: 1100px !important;
    height: 515px !important;
  }

  .layouts-form__modal__wrapper .form__group {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }

  .img__trasformw {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media screen and (min-width: 1290px) and (max-width: 1660px) {
  .layouts-form__modal__wrapper .rodal-fade-enter .rodal-dialog {
  }

  .layouts-form__modal__wrapper .form__group {
    width: 100%;
    height: 100%;
    margin-bottom: 25px;
  }

  .img__trasformw {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .layouts-form__modal__wrapper .rodal-fade-enter .rodal-dialog {
  }

  .layouts-form__modal__wrapper .form__group {
    width: 100%;
    height: 100%;
    margin-bottom: 19px;
  }

  .list__t li {
  }

  .list__t li p {
    font-size: 16;
  }

  .layouts-form__modal__wrapper .form__group .input__group .btn__form-group {
    margin-top: 20px;
  }

  .title__jk {
    font-size: 22px;
  }

  .btns__filter {
    font-size: 17px;
  }

  .btn__filter {
    font-size: 18px;
  }

  .lyts .col-lg-3 {
    flex: 0 0 32% !important;
    max-width: 38%;
  }

  .btn__h-effects:hover {
    width: 83%;
  }

  .img__trasformw {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .btns__filter {
    font-size: 17px;
    width: 28%;
  }

  .btn__filter {
    font-size: 18px;
  }

  .lyts .col-lg-3 {
    flex: 0 0 32% !important;
    max-width: 38%;
  }

  .img__trasformw {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .btns__filter {
    font-size: 17px;
    width: 31%;
  }

  .btn__filter {
    font-size: 18px;
  }

  .lyts .col-lg-3 {
    flex: 0 0 50% !important;
    max-width: 50%;
  }

  .filter__layouts {
    width: 100%;
    display: block;
  }

  .btn__h-effects:hover {
    width: 85%;
  }

  .layouts-modal__wrapper .rodal-fade-enter .rodal-dialog {
    width: 90% !important;
    height: 515px !important;
  }

  .layouts-form__modal__wrapper .rodal-fade-enter .rodal-dialog {
    top: -250px;
    width: 60% !important;
  }

  .layouts-form__modal__wrapper .form__group {
    padding: 35px 35px;
  }

  .layouts-form__modal__wrapper .form__group .input__group {
    margin-top: 25px;
  }

  .wraper-modal-2 {
    min-width: 461px;
    margin-top: -15px;
    height: 440px;
  }

  .wraper-modal-1 img {
    width: 100%;
  }

  .img__trasformw {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 460px) {
  .wraper-modal-1 .toast {
    position: absolute !important;
    top: 225px !important;
    right: 10px !important;
    max-width: 345px !important;
  }
  .tools {
    display: flex;
  }
  .area {
    font-size: 16px;
  }
  .tools .tol__button {
    border-radius: 50%;
    border: none;
    font-weight: bold;
    outline: none;
    margin-right: 5px;
    width: 35px;
    height: 35px;
    background: #2f8cca;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .layouts-form__modal__wrapper
    .rodal-fade-enter
    .rodal-dialog
    .rodal-close:before {
    border-radius: 0 !important;
    background: #000 !important;
    width: 60% !important;
    left: 5px !important;
  }

  .layouts-form__modal__wrapper
    .rodal-fade-enter
    .rodal-dialog
    .rodal-close:after {
    border-radius: 0 !important;
    background: #000 !important;
    width: 60% !important;
    left: 5px !important;
  }

  .layouts-form__modal__wrapper .rodal-fade-enter .rodal-dialog .rodal-close {
    border-radius: 50% !important;
    top: 20px !important;
    right: 20px !important;
    width: 30px !important;
    height: 30px !important;
    border: 2px solid #000 !important;
  }

  .layouts-form__modal__wrapper .rodal-fade-enter .rodal-dialog {
    top: -160px;
    width: 90% !important;
  }

  .layouts-modal__wrapper .rodal-fade-enter .rodal-dialog {
    width: 90% !important;
    height: 515px !important;
  }

  .wraper-modal-1 img {
    max-width: 100%;
  }

  .wraper-modal-2 {
    height: 400px;
  }

  .layouts-form__modal__wrapper .form__group {
    padding: 18px 25px;
  }

  .layouts-form__modal__wrapper .form__group .input__group .btn__form-group {
    margin-top: 15px;
  }

  .layouts-form__modal__wrapper .form__group .input__group {
    margin-top: 0;
  }

  .jk__form {
    position: absolute;
    top: 460px;
    left: 160px;
    width: 200px;
    height: 40px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -1px;
    color: #ffffff;
    background: #0273ba;
    border-radius: 94px;
    border: none;
  }

  .img__group {
    padding: 15px;
  }

  .btn__filter {
    font-size: 18px;
    width: 100%;
  }

  .lyts .col-lg-3 {
    flex: 0 0 100% !important;
    max-width: 100%;
  }

  .filter__layouts {
    width: 100%;
    display: block;
  }

  .sales-wrapper-inner .sales-icon {
    width: 30px;
  }

  .sales-wrapper-inner .icon3 {
    width: 25px;
  }
  .btn__h-effects:hover {
    width: 87%;
  }

  .rodal-dialog {
    animation-duration: 300ms;
  }

  .layouts-modal__img {
    margin-top: 50px;
    max-width: 100%;
    height: auto;
    /* object-fit: cover; */
  }

  .img__trasformw {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
