.payment-section .page-top {
    padding: 0 40px;
    max-width: 100%;
}

.payment-main-wrapper {
    padding: 0 40px;
    margin-bottom: 80px;
}

.payment-main {
    width: 100%;
    padding: 40px;
    border: 1px solid #D7D7D7;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.payment-wrapper {
    flex-basis: 536px;
    flex-shrink: 1;
    margin-right: 30px;
}

.payment-img {
    border-radius: 4px;
    overflow: hidden;
    flex-shrink: 1;
}

.payment-img img {
    width: 100%;
    object-fit: cover;
}

.installment {
    margin-top: 1.5rem;
}

.payment__wrapper-main {
    width: 100%;
    padding: 80px;
}

.payment__text-title {
    font-family: 'Montserrat', sans-serif;
    margin-top: 0;
    font-weight: 700;
    font-size: 34px;
    line-height: 50px;
    letter-spacing: -0.01em;
    color: #E7716A;
    margin-bottom: 20px;
}

.payment__text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 21px;
    line-height: 30px;
    color: #000;
    margin-bottom: 40px;
}

.payment__text .blue {
    color: #0273BA;
    font-weight: 600;
}

.payment__btn-mt {
    margin-top: 15px;
}

.payment__btn {
    padding: 16px 36px;
    display: block;
    width: 289px;
    background: #0273BA;
    border-radius: 2px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: #fff;
    border: none;
    transition: all .5s;
}

.payment__btn:hover {
    background: #075D93;
}

.payment__btn button img {
    padding-right: 10px;
}
.percent p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    /* Blue Color Armon */

    color: #0273BA;

}

.percent__l p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    /* Blue Color Armon */

    color: #0273BA;

}

.installment__text p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.5px;

    /* Black Color Armon */

    color: #080B11;

}

@media (max-width: 1200px) {
    .payment__text-title {
        font-size: 31px;
        line-height: 34px;
    }

    .payment__text p {
        font-size: 17px;
        line-height: 1.6;
        margin-bottom: 30px;
    }
}


@media (max-width: 1120px) {
    .payment__text-title {
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 15px;
    }

    .payment__text p {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 30px;
    }
}

@media (max-width: 1024px) {
    .payment__text-title {
        font-size: 23px;
        line-height: 26px;
    }

    .payment__text p {
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 30px;
    }

    .payment__text {
        margin-bottom: 20px;
    }

    .payment__btn {
        padding: 10px 20px;
        width: 220px;
    }
}


@media (max-width: 992px) {
    .payment__text-title {
        font-size: 25px;
        line-height: 28px;
        margin-bottom: 15px;
    }

    .payment__text p {
        font-size: 15px;
        line-height: 1.5;
        margin-bottom: 30px;
    }
}


@media (max-width: 960px) {

    .payment-main-wrapper {
        margin-bottom: 40px;
    }

    .payment-img {
        flex-shrink: 2;
    }
}

@media (max-width: 940px) {
    .payment__text-title {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 15px;
    }

    .payment__text p {
        font-size: 13px;
        line-height: 1.5;
    }

    .payment__text {
        margin-bottom: 20px;
    }

    .payment-img {
        flex-shrink: 1.5;
    }

    .payment__btn {
        padding: 9px 10px;
        width: 200px;
        font-size: 13px;
    }
}


@media (max-width: 860px) {
    .payment__text-title {
        font-size: 23px;
        line-height: 26px;
        margin-bottom: 10px;
    }

    .payment__text p {
        font-size: 12px;
        line-height: 1.4;
        margin-bottom: 10px;
    }

    .payment__text {
        margin-bottom: 20px;
    }

    .payment-img {
        flex-shrink: 1.5;
    }

    .payment__btn {
        padding: 9px 10px;
        width: 200px;
        font-size: 13px;
    }
}


@media (max-width: 790px) {
    .payment-main-wrapper {
        margin-bottom: 40px;
    }

    .payment__text-title {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 8px;
    }

    .payment__text p {
        font-size: 12px;
        line-height: 1.4;
        margin-bottom: 10px;
    }

    .payment__text {
        margin-bottom: 20px;
    }

    .payment-img {
        flex-shrink: 1.5;
    }

    .payment__btn {
        padding: 9px 10px;
        width: 200px;
        font-size: 13px;
    }
}

@media (max-width: 771px) {
    .payment-img {
        flex-grow: 1.2;
    }
}

@media (max-width: 768px) {
    .payment-section .page-top, .payment-main-wrapper {
        padding: 0;
    }
}

@media (max-width: 680px) {

    .payment-section.page-wrapper {
        max-width: 328px;
    }

    .payment-main {
        border: none;
        flex-wrap: wrap;
        max-width: 328px;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
        border-radius: 2px;
        padding: 0;
        margin: 0 auto;
    }

    .payment-img {
        height: 184px;
        order: -1;
        border-radius: 4px 4px 0 0;
    }

    .payment-wrapper {
        margin-right: 0;
        padding: 20px;
        flex-basis: 100%;
    }

    .payment__text-title {
        font-size: 20px;
        line-height: 24px;
        color: #080B11;
        margin-bottom: 12px;
    }

    .payment__text {
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, .65);
        margin-bottom: 20px;
    }

    .payment__btn {
        max-width: 100%;
        width: 100%;
        border-radius: 2px;
    }
}
