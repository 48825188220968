.top__news {
  max-width: 840px;
  margin: 40px auto;
}

.top__news .projects-wrapper {
  padding: 0 30px;
}

.news {
  position: relative;
  width: 100%;
  /* margin-top: 1rem; */
  /* height: 400px;/ */
  /* background: url("../../assets/icons/fon.svg"); */
  background-repeat: repeat-x;
  display: flex;
  align-items: center;
  /* margin-bottom: 30px; */
  z-index: 1;
  max-width: 850px;
  margin: 0 auto 40px;
}

.top__news .projects-wrapper {
  /*padding: 0 20px;*/
}

.news .projects-wrapper {
  width: 100%;
}

@media (max-width: 1024px) {
  .top__news {
    max-width: 700px;
  }

  .news {
    max-width: 710px;
  }
}

@media (max-width: 768px) {
  .top__news {
    max-width: 600px;
    margin-bottom: 20px !important;
  }

  .news {
    max-width: 610px;
  }
}

@media (max-width: 610px) {
  .top__news .projects-wrapper {
    padding: 0 25px;
  }

  .news .projects-wrapper {
    padding: 0 18px;
  }
}

@media (max-width: 590px) {
  .news, .top__news {
    max-width: 400px;
  }
}

.news .container {
  max-width: 100%;
}

.news .carousel-container {
  /*height: 200px;*/
  /*width: 100%;*/
}

.news .carousel-container ul {
  width: 100%;
  height: 100%;
}

.news a {
  text-decoration: none !important;
}

.news-header {
  width: 100%;
  height: auto;
  /* margin-left: 41px; */
  /*margin-bottom: 40px;*/
}

.under-test {
  width: 121px;
  height: 6px;
  background: #0273ba;
  border-radius: 2px;
}

.news-cards {
  width: 100%;
  /* margin-left: 40px; */
  min-height: 192px;
}

.news-header p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #0273BA;
  opacity: 0.48;
  margin-bottom: 8px;
}

.news-header span {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 34px;
  line-height: 50px;
  letter-spacing: -0.01em;
  color: #080B11;

}

.news-more {
  /*margin-top: 20px;*/
  margin-right: 10px;
}

.news-header button:hover {
  color: #ffffff;
  background: #0273ba;
  border: none;
}

.news-header button:hover a {
  color: #ffffff;
}

.news-header button a {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  text-decoration: none !important;
  color: #2b2b2b;
}

.news-card {
  position: relative;
  padding: 10px;
  width: 100%;
  height: 200px;
  background: #ffffff;
  border: 1px solid #e9e9e9 !important;
  box-shadow: none !important;
  border-radius: 4px;
  /* margin: 0 0 20px 0; */
  transition: all .5s;
}

.news-card:hover {
  border: 1px solid #0273BA !important;
}

.news-dates {
  position: absolute;
  bottom: 0;
  left: 10px;
}

.jVJmF {
  /* width: 99% !important; */
  grid-gap: 17px !important;
}

.wrapper-news-card {
  margin: 0 8px;
}

.news-card img {
  width: 100%;
  height: 200px;
  border-radius: 12px;
}

.news-card h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #080B11;
}

.news-card span {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 16px;
  color: #2b2b2b;
}

.text__news-t p, .text__news-t a {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #080B11;
  opacity: 0.65;
}

.news-card .news-dates {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #0273BA;
  opacity: 0.48;
}

.news-cards .sc-bdVaJa span {
  display: none;
}

@media (max-width: 1920px) {
  .news-card {
    /* margin-bottom: 35px !important; */
    padding: 20px;
    height: 192px;
  }
}

@media (max-width: 1440px) {
  .news-card {
    padding: 20px;
    /* margin-bottom: 35px !important; */
  }
}

@media (max-width: 1000px) {

  .news-header {
    width: 100%;
    height: auto;
    margin-left: 0px;
  }

  .news-header button {
    width: 200px;
    height: 35px;
    font-size: 12px !important;
  }

  .news-card {
    /*margin-top: 2rem !important;*/
    width: 100% !important;
    /*margin-bottom: 35px !important;*/
    margin-left: 0;
  }
}


@media (max-width: 768px) {
  .news .container {
    margin: 0;
  }

  .news-header button {
    display: none;
  }

  .news-card h2 {
    font-size: 12px;
    line-height: 17px;
  }

  .news-header span {
    font-size: 24px;
    line-height: 29px;
  }

}

@media (max-width: 590px) {
  .news-card {
    /*width: 248px !important;*/
    /*width: 400px !important;*/

  }

  .news-item {
    /*width: 240px !important;*/
    /*width: 300px !important;*/
    /*margin: 0 20px;*/
  }
}
/*
@media (max-width: 800px) {
    .wrapper-news-card {
        margin-left: 10px;
    }

    .news-card {
        margin-top: 2rem !important;
        width: 100% !important;
        min-height: 200px;
        margin-bottom: 35px !important;
        margin-left: -5px;
    }
}

@media (max-width: 767px) {
    .news-card {
        margin-top: 2rem !important;
        width: 100% !important;
        min-height: 300px;
        margin-bottom: 35px !important;
    }
} */

/* @media (max-width: 425px) {
    .news-card {
        margin-top: 2rem !important;
        width: 100% !important;
        min-height: 150px !important;
        margin-bottom: 40px !important;
    }
}

@media (max-width: 375px) {
    .news-card {
        margin-top: 2rem !important;
        width: 100% !important;
        height: 250px !important;
        margin-bottom: 35px !important;
        margin-left: -5px;
    }
} */
