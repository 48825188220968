.top__insta {
  max-width: 840px;
  margin: 40px auto 0;
}

.instagram {
  max-width: 840px;
  margin: 0 auto 45px;
  background-repeat: repeat-x;
  background-size: 825px;
  display: flex;
  align-items: center;
}

.instagram-card {
  margin: 20px;
}

@media (max-width: 1024px) {
  .top__insta, .instagram {
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  .top__insta, .instagram {
    max-width: 600px;
  }
}

@media (max-width: 590px) {
  .top__insta, .instagram {
    max-width: 400px;
  }
}

.instagram .projects-wrapper {
  padding: 0 10px;
  width: 100%;
}

.instagram-projects-header .projects-wrapper {
  padding: 0 30px;
}

.instagram .carousel-container {
  height: 300px;
}

.instagram-projects-header {
  width: 100%;
  height: auto;
  margin-top: 40px;
  /* margin-left: 43px; */
}

.under-test {
  width: 121px;
  height: 6px;
  background: #0273ba;
  border-radius: 2px;
  margin-bottom: 8px;
}

.instagram-projects-header p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #0273BA;
  opacity: 0.48;
  margin-bottom: 0;
}

.instagram-projects-header span {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 34px;
  line-height: 50px;
  letter-spacing: -0.01em;
  color: #080B11;
}

.instagram-card {
  background: #fff;
  height: auto;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  /*width: 100% !important;*/
  max-width: 248px;
  overflow: hidden;
  transition: all .5s;
}

.instagram-card:hover {
  box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.2);
}

.instagram-projects-cards .bVoXXg {
  margin-left: 0 !important;
}

.img-inst {
  width: 100%;
  height: 150px;
}

.instagram-card img {
  width: 100%;
  height: 100%;
}

.like-inst {
  display: flex !important;
  margin: 10px;
}

.like-inst span {
  margin: 10px;
}

@media (max-width: 992px) {
  /*.instagram-card {*/
  /*  margin: 10px;*/
  /*}*/
}

@media (max-width: 768px) {
  .instagram-projects-header {
    margin-top: 10px;
    width: 85% !important;
    z-index: 10;
  }
}

@media (max-width: 768px) {
  .instagram-projects-header {
    margin-top: 21px;
    width: 85% !important;
    z-index: 10;
  }

  .instagram-projects-header span {
    font-size: 24px;
    line-height: 29px;
  }

  .instagram-card {
    /*margin: 15px;*/
  }

  .react-multi-carousel-dot-list {
    margin-left: 0 !important;
  }
}
@media (max-width: 768px) {
  .instagram-projects-header {
    margin-bottom: 0;
  }
}

@media (max-width: 560px) {
  .instagram .carousel-container {
    height: auto;
  }
}

@media (max-width: 425px) {
  .instagram-projects-header {
    margin-bottom: 0;
  }

  .instagram-card {
    /*margin: 10px;*/
  }
}