.footer {
  /* position: relative; */
  width: 100%;
  height: auto;
  background: #333333;
  z-index: 9;
}

.footer-header {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone-background {
  line-height: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #bfbfbf !important;
}

.items {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.items p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin: 0.5rem;
}

.footer-logo {
  padding: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.basement {
  width: 100%;
  height: auto;
  background: #2c2a2f;
  padding: 20px 0;
}

.basement li {
  list-style: none;
}

.basement ul {
  padding: 0;
}

.icons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.icons svg {
  margin-left: 8px;
  margin-right: 8px;
}

.main svg {
  /* padding: 0 10px 10px; */
}

.substr {
  /* padding: 1rem; */
}

.footer-address .footer-address__text_bott {
  text-decoration: none !important;
  color: #bfbfbf !important;
  outline: none;
  font-size: 14px;
}

.substr p {
  color: #bfbfbf;
}

.input-group input {
  background: #333333 !important;
  border: 1px solid #737277 !important;
  color: #ffffff !important;
  outline: none !important;
}

.input-group-append button {
  color: #ffffff;
  border: none !important;
  background: #737277 !important;
}

.info-phone {
  padding-left: 2.5rem;
  padding-top: 1rem;
}

.info-address {
  padding: 1rem 0 0 5rem;
  /* margin-left: 50px; */
}

.info-txt li {
  list-style: none;
}

.info-txt li p a {
  text-decoration: none !important;
  color: #bfbfbf !important;
}

.info-txt li .phone-color {
  line-height: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #bfbfbf;
  text-decoration: none !important;
}

.map-link {
  color: #747278 !important;
}

.info-txt li p {
  line-height: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #bfbfbf;
}

.main {
  display: block;
  color: #bfbfbf;
  padding: 20px 0 0;
}

.footer-address__text {
  color: #bfbfbf;
}

.footer-address__text_color {
  color: #747278 !important;
  text-decoration-line: underline;
}

.footer-address__text_marg {
  margin-bottom: 0 !important;
  font-size: 14px;
}

.footer-address__text_bott {
  margin-bottom: 6px;
}

.footer__logo img {
  object-fit: cover;
  width: 185px;
  margin-bottom: 6px;
}

.mobile-footer {
  display: none;
}

.under-text {
  width: 100%;
  height: 3px;
  background: #c4c4c4;
  border-radius: 2px;
  margin-top: 50px;
  margin-bottom: 15px;
}

.footer-bttm-wrap {
  margin-top: 40px;
  padding: 8px 0;
  background: #000;
}

.footer-bttm-wrap p {
  margin: 0;
  font-size: 14px;
}

/* @media screen and (min-width: 1366) and (max-width: 1920px) {
    .icons-text {
        margin-left: 96px !important;
        padding: 35px 20px 15px;
    }
} */

.under-footer__logo img {
  max-width: 210px;
}

@media (max-width: 1024px) {
  .footer__logo img {
    object-fit: cover;
    width: 200px;
  }

  .icons svg {
    width: 32px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .main {
    padding: 20px 0 0;
  }

  .footer-address__text_color,
  .footer-address__text {
    font-size: 11px;
  }

  .footer-address_margi {
    margin-left: -3px;
    margin-right: -52px;
  }

  .input-group {
    width: 80% !important;
    margin-right: 0;
    margin-left: auto;
  }

  .footer-address__text_left {
    width: 80% !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .mobile-under-footer__text {
    padding-left: 10%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #bfbfbf;
    text-align: left;
    max-width: 60%;
  }

  .mobile__under-footer-img {
    padding-right: 31% !important;
  }

  .icons {
    display: flex;
    justify-content: center;
  }

  .icons-text {
    margin-left: 0;
  }

  .icons-text span {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #bfbfbf;
  }

  .basement span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #bfbfbf;
  }

  .links {
    display: none;
  }

  .full_links {
    display: none;
  }

  .m-links {
    display: block;
    padding-top: 1.5rem;
  }

  .l-center {
    margin-bottom: 20px;
  }

  .l-center:last-of-type {
    margin-bottom: 0;
  }

  .m-links span {
    margin-right: 25px;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #f2f2f2;
  }

  .m-links span:last-of-type {
    margin-right: 0;
  }

  .main {
    display: none;
  }

  .mobile-footer {
    display: block;
    width: 100%;
  }

  .f-logo {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .f-logo svg {
    padding: 10px 10px 10px;
  }

  .armon-info {
    width: 100%;
    height: auto;
  }

  .info-txt {
    padding: 15px 0 0 !important;
    margin: 0 !important;
  }

  .info-txt li a {
    color: #747278 !important;
    outline: none !important;
  }

  .info-txt li p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #bfbfbf;
  }

  .substr {
    width: 100%;
    height: auto;
  }

  .substr p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #bfbfbf;
  }

  .input-group {
    margin: 0 auto;
  }

  .footer-bttm-wrap {
    margin-top: 0;
  }

  /* .info li p {
        display: flex !important;
        justify-content: center !important;
    } */

  /* .info-address {
        display: flex;
        justify-content: center;
        padding: 0;
    } */
}
