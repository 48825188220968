.navbar-collapse {
    display: flex;
}

.navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.header-icon {
    display: flex;
    margin-top: -3px;
}

.header-icon-black {
    display: flex;
    cursor: pointer;
}

.header-icon-black img {
    margin-left: 10px;
    /* margin-right: 10px; */
    display: none;
    cursor: pointer;
}

.header-icon-black img:hover {
    cursor: pointer;
    filter: invert(.5) sepia(1) saturate(5) hue-rotate(172deg);
}

.header-icon img {
    display: block;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}

.header-icon img:hover {
    cursor: pointer;
    filter: invert(.5) sepia(1) saturate(5) hue-rotate(172deg);
}

.menu li {
    display: flex;
}

.menu li a {
    font-size: 14px;
    font-weight: 600;
    display: block;
    color: #000;
    text-decoration: none;
    padding: 6px 12px;
    transition: all 0.3s ease-in-out 0s;
}

.menu li:hover .links-headers {
    opacity: 1;
    border-radius: 4px;
    color: #fff !important;
    background: #0273BA;
}

.menu li:hover .submenu__trasparent-bg {
    display: block;
}

.black-collor ul li {
    color: #fff !important;
}

@media (max-width: 1920px) {
    .menu li a {
        font-size: 16px;
    }
}

@media (max-width: 1440px) {
    .menu li a {
        font-size: 18px;
    }
}

@media (max-width: 1280px) {
    .menu li a {
        font-size: 16px;
    }
}

@media (max-width: 1180px) {
    .menu li a {
        font-size: 15px;
    }
}

@media (max-width: 1024px) {
    .menu li a {
        font-size: 14px;
    }
}

@media (max-width: 1000px) {

    .navbar-collapse {
        display: none;
    }
}