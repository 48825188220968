.sub-menu {
    width: 100%;
    height: 0;
    border-radius: 6px;
    opacity: 0;
    /* display: none; */
    visibility: hidden;
    position: relative;
}


.sub-menu li a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 18px !important;
    line-height: 35px !important;
    color: #FFFFFF;
}

@media (max-width: 1000px) {
    .sub-menu {
        background: none;
    }
}