body {
  background: #fff;
  font-family: "Montserrat", sans-serif;
}

.affix {
  transition-duration: 2s !important;
  background: #fff !important;
  -webkit-transition: padding 0.4s ease-out;
  transition: padding 0.4s ease-out;
  box-shadow: 0px 0px 8px rgb(62, 62, 62);
}

.affix .head-m {
  padding: 10px 50px;
}

.affix .menu li {
  transition: 0.1s ease;
}

.armon_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: hidden !important;
}

.affix .menu li .links-headers {
  color: #000 !important;
}

.affix .menu li:hover .links-headers {
  opacity: 0.8;
  border-radius: 6px;
  color: #fff !important;
  background: #343237;
}

.affix .submenu__trasparent-bg {
  position: absolute;
  color: #fff;
  top: 60px;
  width: 70px;
  height: 250px;
  padding-top: 12px;
  z-index: 5;
  display: none;
}

.affix .submenu__trasparent-bg .submenu__wrapper {
  width: 200px;
  background: #343237 !important;
  color: #fff !important;
  opacity: 0.98;
  border-radius: 6px;
  box-shadow: 0 0 30px rgb(0 0 0 / 20%);
  z-index: 999;
}

/* .affix .submenu__wrapper::before {
  box-shadow: 10px 2px 10px #00000038;
  display: inline-block;
  margin-left: 2.25rem;
  position: absolute;
  color: #343237;
  top: -0.5rem;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.5rem solid;
  border-right: 0.5rem solid transparent;
  border-bottom: 0;
  border-left: 0.5rem solid transparent;
  transform: rotateX(180deg);
} */
.dropdown-menu__list li a {
  color: #fff !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  /* White Color Armon */

  color: #ffffff;
}

.affix .dropdown-menu__list li a {
  color: #fff !important;
}

.affix .dropdown-menu__list li:hover {
  background: #343237;
}

/* .affix .navbar-brand .logo-header-r {
  display: block;
}

.affix .navbar-brand .logo-header-l {
  display: none;
} */

.affix .navbar-collapse .header-icon img {
  display: none;
}

.affix .navbar-collapse .header-icon-black img {
  display: block;
}

.affix .nav-button #nav-icon3 span {
  background: #000;
}

.affix .nav-open #nav-icon3 span:nth-child(1) {
  background: #fff;
}

@media (min-width: 320px) and (max-width: 768px) {
  .affix .head-m {
    padding: 0px 10px;
    margin-top: 0px;
    height: 75px;
  }
}

/* 

@media (min-width: 576px) and (max-width: 767.98px) {
  .container_width {
    max-width: 540px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .container_width {
    max-width: 720px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .container_width {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container_width {
    max-width: 1140px;
  }
} 
*/
@media (min-width: 1440px) and (max-width: 1920px) {
  .container {
    /*width: 1085px !important;*/
    /*margin: 0 auto;*/
  }
}
