.banner-section {
    max-width: 840px;
    margin: 20px auto;
}

.banner__carousel {
    margin: 0 auto;
    width: 100%;
}

@media (max-width: 1024px) {
    .banner-carousel-item:nth-of-type(2) {
        order: 1;
    }
}

.banner-carousel-item {
    /*margin: 0 10px;*/
    margin: 0 auto;
    padding: 0 10px;
}

.banner-section .carousel {
    width: 100%;
}

@media (max-width: 1024px) {
    .banner-section {
        max-width: 700px;
    }
}

@media (max-width: 768px) {
    .banner-section {
        max-width: 600px;
        margin-bottom: 0;
    }
}

@media (max-width: 590px) {
    .banner-section {
        max-width: 400px;
    }

    .banner-carousel-item {
        padding: 0 12px;
    }
}

/*@media (max-width: 560px) {*/
/*    .banner__carousel {*/
/*        height: 160px;*/
/*    }*/
/*}*/

/*@media (max-width: 510px) {*/
/*    .banner__carousel {*/
/*        width: 100%;*/
/*        max-width: 100%;*/
/*        height: 100px;*/
/*        padding: 0 20px;*/
/*    }*/
/*}*/
/*@media screen and (max-width: 1920px) {*/
/*    .wrapper {*/
/*        width: 100%;*/
/*    }*/

/*    .wrapper img {*/
/*        width: 130px;*/
/*    }*/

/*    .wrapper-inner {*/
/*        !* padding: 99px 0px 90px 50px; *!*/
/*    }*/

/*    .wrapper__img_height {*/
/*        width: 90px !important;*/
/*    }*/
/*}*/

/*@media screen and (min-width: 1440px) and (max-width: 1920px) {*/
/*    .banner-section {*/
/*        !* margin-top: 90px;*/
/*        margin-bottom: 60px; *!*/
/*    }*/

/*    .wrapper {*/
/*        width: 100%;*/
/*    }*/

/*    .wrapper img {*/
/*        width: 150px;*/
/*    }*/

/*    .wrapper-inner {*/
/*    }*/

/*    .wrapper__img_height {*/
/*        width: 115px !important;*/
/*    }*/
/*}*/

/*@media (min-width: 1400px) {*/
/*    .wrapper {*/
/*        width: 100%;*/
/*        height: 190px;*/
/*    }*/
/*}*/

/*@media (max-width: 1400px) {*/
/*    .wrapper {*/
/*        width: 100%;*/
/*        height: 190px;*/
/*    }*/

/*    .wrapper_height {*/
/*        height: 190px !important;*/
/*    }*/

/*    .wrapper__img_height {*/
/*        width: 145px !important;*/
/*    }*/

/*    .wrapper-inner {*/
/*        !* padding: 65px 0 60px 45px !important; *!*/
/*    }*/

/*    .wrapper img {*/
/*        width: 155px;*/
/*        margin: 0 -18px 0 auto !important;*/
/*    }*/
/*}*/

/*@media (max-width: 1200px) {*/
/*    .wrapper {*/
/*        width: 78%;*/
/*        height: 180px;*/
/*    }*/

/*    .wrapper-inner {*/
/*        padding: 45px 0 45px 20px !important;*/
/*    }*/

/*    .wrapper img {*/
/*        width: 133px;*/
/*        margin: 0 -15px 0 auto !important;*/
/*    }*/

/*    .wrapper_height {*/
/*        height: 170px !important;*/
/*    }*/

/*    .wrapper__img_height {*/
/*        width: 120px !important;*/
/*    }*/

/*    .wrapper_height {*/
/*        height: 180px !important;*/
/*    }*/
/*}*/

/*@media (max-width: 1024px) {*/
/*    .wrapper {*/
/*        width: 100%;*/
/*    }*/
/*}*/

/*@media (max-width: 992px) {*/
/*    .wrapper {*/
/*        width: 95%;*/
/*    }*/

/*    .wrapper-inner {*/
/*        padding: 30px 0 30px 16px !important;*/
/*    }*/

/*    .wrapper-inner p {*/
/*        font-size: 18px !important;*/
/*    }*/

/*    .wrapper-inner h4 {*/
/*        font-size: 28px !important;*/
/*    }*/

/*    .wrapper img {*/
/*        width: 90px;*/
/*        margin: 0 -9px 0 auto !important;*/
/*    }*/

/*    .wrapper_height {*/
/*        height: 130px !important;*/
/*    }*/

/*    .wrapper__img_height {*/
/*        width: 92px !important;*/
/*    }*/
/*}*/

/*@media (max-width: 768px) {*/
/*    .banner-section {*/
/*        margin-left: 0;*/
/*        height: 250px;*/
/*    }*/

/*    .wrapper {*/
/*        width: 100%;*/
/*        height: 80px;*/
/*        border-radius: 10px;*/
/*    }*/

/*    .wrapper_height {*/
/*        height: 140px;*/
/*    }*/

/*    .wrapper-inner {*/
/*        padding: 21px 0px 5px 8px !important;*/
/*    }*/

/*    .wrapper-inner p {*/
/*        font-size: 11px !important;*/
/*    }*/

/*    .wrapper-inner h4 {*/
/*        font-size: 16px !important;*/
/*    }*/

/*    .wrapper img {*/
/*        width: 60px;*/
/*        margin: 0 -7px 0 auto !important;*/
/*    }*/

/*    .wrapper_height {*/
/*        height: 83px !important;*/
/*    }*/

/*    .wrapper__img_height {*/
/*        width: 60px !important;*/
/*    }*/
/*}*/

/*@media (max-width: 455px) {*/
/*    .banner-section {*/
/*        height: 200px;*/
/*    }*/

/*    .wrapper {*/
/*        width: 100%;*/
/*        border-radius: 10px;*/
/*    }*/

/*    .wrapper-inner {*/
/*        padding: 7px 0 0 7px !important;*/
/*    }*/

/*    .wrapper-inner p {*/
/*        font-size: 8px !important;*/
/*    }*/

/*    .wrapper-inner h4 {*/
/*        font-size: 9px !important;*/
/*    }*/

/*    .wrapper img {*/
/*        width: 55px;*/
/*        margin: 0 -2px 0 auto !important;*/
/*    }*/

/*    .wrapper_height {*/
/*        height: 83px !important;*/
/*    }*/

/*    .wrapper__img_height {*/
/*        width: 51px !important;*/
/*    }*/
/*}*/

/*@media (max-width: 425px) {*/
/*    .banner-section {*/
/*        height: 200px;*/
/*    }*/

/*    .wrapper {*/
/*        width: 100%;*/
/*        border-radius: 10px;*/
/*    }*/

/*    .wrapper-inner {*/
/*        padding: 7px 0 0 7px !important;*/
/*    }*/

/*    .wrapper-inner p {*/
/*        font-size: 8px !important;*/
/*    }*/

/*    .wrapper-inner h4 {*/
/*        font-size: 9px !important;*/
/*    }*/

/*    .wrapper img {*/
/*        width: 55px;*/
/*        margin: 0 -2px 0 auto !important;*/
/*    }*/

/*    .wrapper_height {*/
/*        height: 83px !important;*/
/*    }*/

/*    .wrapper__img_height {*/
/*        width: 51px !important;*/
/*    }*/
/*}*/

/*@media (max-width: 375px) {*/
/*    .banner-section {*/
/*        height: 200px;*/
/*    }*/

/*    .wrapper {*/
/*        width: 98%;*/
/*        height: 60px;*/
/*        border-radius: 10px;*/
/*    }*/

/*    .wrapper-inner {*/
/*        padding: 13px 0 18px 6px !important;*/
/*    }*/

/*    .wrapper-inner p {*/
/*        font-size: 8px !important;*/
/*        line-height: 12px !important;*/
/*        margin: 0;*/
/*    }*/

/*    .wrapper-inner h4 {*/
/*        font-size: 9px !important;*/
/*        margin: 0;*/
/*    }*/

/*    .wrapper img {*/
/*        width: 44px;*/
/*        margin: 0 -2px 0 auto !important;*/
/*    }*/

/*    .wrapper_height {*/
/*        height: 62px !important;*/
/*    }*/

/*    .wrapper__img_height {*/
/*        width: 36px !important;*/
/*    }*/
/*}*/

/*@media (max-width: 340px) {*/
/*    .wrapper {*/
/*        width: 98%;*/
/*        border-radius: 10px;*/
/*    }*/

/*    .wrapper-inner {*/
/*        padding: 12px 0 12px 6px !important;*/
/*    }*/

/*    .wrapper-inner p {*/
/*        font-size: 8px !important;*/
/*        line-height: 12px !important;*/
/*        margin: 0;*/
/*    }*/

/*    .wrapper-inner h4 {*/
/*        font-size: 11px;*/
/*    }*/

/*    .wrapper img {*/
/*        width: 34px;*/
/*        margin: 0 -2px 0 auto !important;*/
/*    }*/

/*    .wrapper_height {*/
/*        height: 55px !important;*/
/*    }*/

/*    .wrapper__img_height {*/
/*        width: 27px !important;*/
/*    }*/
/*}*/

/*@media (max-width: 320px) {*/
/*    .wrapper {*/
/*        width: 98%;*/
/*        border-radius: 10px;*/
/*    }*/

/*    .wrapper-inner {*/
/*        padding: 10px 0 10px 6px !important;*/
/*    }*/

/*    .wrapper-inner p {*/
/*        font-size: 8px !important;*/
/*        margin: 0;*/
/*    }*/

/*    .wrapper-inner h4 {*/
/*        font-size: 10px !important;*/
/*    }*/

/*    .wrapper img {*/
/*        width: 34px;*/
/*        margin: 0 -2px 0 auto !important;*/
/*    }*/

/*    .wrapper_height {*/
/*        height: 55px !important;*/
/*    }*/

/*    .wrapper__img_height {*/
/*        width: 24px !important;*/
/*    }*/
/*}*/
