.page-wrapper {
    max-width: 1280px;
    padding: 0 16px;
    margin: 0 auto;
    flex-grow: 1;
}

.page-top {
    display: flex;
    justify-content: space-between;
    margin: 46px 0;
}

.page-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: #000000;
}

.breadcrumbs {
    text-transform: lowercase;
}

.breadcrumbs a {
    color: #000;
}

@media (max-width: 840px) {
    .breadcrumbs {
        display: none;
    }
}

@media (max-width: 640px) {
    .breadcrumbs {
        display: none;
    }
    
    .page-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.01em;
        color: #080B11;

    }

    .page-top {
        margin: 40px 0 20px;
    }
}