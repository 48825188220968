.job-card {
    width: 392px;
    border-radius: 4px;
    overflow: hidden;
    /*border: 1px solid #EDEDED;*/
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
    font-family: 'Montserrat', sans-serif;
    height: 100%;
    box-sizing: border-box;
    min-height: 424px;
    transition: all .5s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.job-card:hover {
    box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.2);
}

.job-type {
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    margin: 0 0 8px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, .48);
    height: 16px;
}

.job-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.01em;
    color: #080B11;
    margin: 8px 0;
}

.job-text {

    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: rgba(8, 11, 17, .65);
}

.job-img {
    max-width: 392px;
    height: 220px;
}

.job-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
}

.job-info {
    padding: 20px;
    border: 1px solid #EDEDED;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    flex-grow: 1;
}

.job-info-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.job-info-list li {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: rgba(0, 0, 0, .65);
    margin: 8px 0;
}

.job-subtitle {
    color: #0273BA;
}

.job-info-list a {
    color: rgba(0, 0, 0, .65);
}

/*@media (min-width: 1245px) {*/
/*    .job-card {*/
/*        margin: 0 20px !important;*/
/*    }*/
/*}*/


@media (max-width: 640px) {

    .job-img {
        height: 184px;
    }

    .job-card {
        width: 328px;
    }

    .job-info {
        padding: 12px;
    }

    .job-title {
        font-size: 20px;
        line-height: 24px;
        margin: 8px 0;
    }
}