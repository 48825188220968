.header {
    box-shadow: 0px 18px 34px -22px rgba(0, 0, 0, 0.25);
}

.navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar {
    transition-duration: .8s !important;
}

.head-m {
    width: 100%;
    padding: 3px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 320px) and (max-width: 768px) {
    .head-m {
        padding: 0px 10px;
        margin-top: 0px;
        height: 75px;
    }
}