.video-gallery {
    width: 100%;
    height: auto;
    max-width: 840px;
    margin: 40px auto;
}

.video-gallery .projects-wrapper {
    padding: 0 30px;
}

.video-gallery-header .projects-wrapper {
    padding: 0 30px;
}

.video-wrapper {
    display: flex;
}

@media (max-width: 1024px) {
    .video-gallery {
        max-width: 700px;
    }
}

@media (max-width: 768px) {
    .video-gallery {
        max-width: 600px;
    }

    .video-gallery .projects-wrapper {
        padding: 0 20px;
    }

    .video-gallery-header .projects-wrapper {
        padding: 0 30px;
    }
}

@media (max-width: 590px) {
    .video-gallery {
        max-width: 400px;
    }
}

.bghjHX {
    margin: 0 !important;
}

.kICXHd:nth-child(1) {
    padding-left: 0 !important;
    margin-right: -42px !important;
}

.hyFJQL:nth-child(1) {
    padding-left: 0 !important;
    margin-right: -42px !important;
}

.kVtMtA {
    background: #000;
    opacity: 1;
    left: -40px !important;
    box-shadow: none !important;
}

.sc-bdVaJa {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 100px;
    width: 100%;
    z-index: -1;
}

.kVtMtA::before {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
}


.bhRnqO {
    background: #000;
    opacity: 1;
    right: -40px !important;
    box-shadow: none !important;
}

.bhRnqO::before {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
}


/* //////////// Video effect */

.video-container {
    position: relative;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    max-width: 392px;
    width: 100%;
    height: 218px;
    margin: 0 10px 0 0;
    transition: all .5s;
    border-radius: 4px;
    overflow: hidden;
}

.video-container:last-of-type {
    margin-right: 0;
}

.video-container .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bVoXXg:last-of-type .video-container {
    margin-right: 0;
    margin-left: 10px;
}

iframe {
    border-radius: 4px !important;
}

.text-loading {
    position: absolute;
    margin: auto !important;
    bottom: 0;
    display: flex;
    top: 0;
    align-items: center;
    justify-content: center;
    color: #fff;
    left: 0;
    right: 0;
}

.modal-xl .modal-header .close {
    opacity: 1 !important;
    padding: 0 !important;
    width: 30px !important;
    margin: 0 0 0 auto !important;
    height: 30px !important;
    border-radius: 50% !important;
    border: 2px solid #fff !important;
    color: #ffffff !important;
    font-weight: 100 !important;
}

.modal-header {
    z-index: 99 !important;
    display: flex !important;
    align-items: flex-start !important;
    justify-content: space-between !important;
    /* padding: 0 !important; */
    border-bottom: none !important;
    border-top-left-radius: none !important;
    border-top-right-radius: none !important;
}

.image {
    opacity: 1;
    display: block;
    /*width: 100%;*/
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: 0.5s ease;
    /*opacity: 0;*/
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.video-container:hover {
    box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.2);
}

.video-container:hover .image {
    /*filter: brightness(40%);*/
}

.video-container:hover .middle {
    opacity: 1;
}

.play-btn {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.play-btn img {
    width: 40px;
    height: 40px;
}

/* ////////////////// and video effect */

.kVtMtA {
    left: -13px;
}

.bhRnqO {
    right: -2px;
}

.ecdVOt {
    width: 11px;
    height: 11px;
    border: 2px solid #6c757d;
}

.qbpMB {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #000;
}

.video-gallery-header {
    margin-top: 50px;
    margin-bottom: 35px;
    width: 100%;
}

.video-gallery-header p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #0273BA;
    opacity: 0.48;
    margin-bottom: 8px;
}

.video-gallery-header span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 34px;
    line-height: 50px;
    letter-spacing: -0.01em;
    color: #080B11;
}

.video-gallery-header button {
    display: block;
    float: right;
    width: 150px;
    height: 40px;
    background: #ffffff;
    border: 3px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 11px;
}

.video-gallery-header button:hover {
    box-shadow: 0px 0px 10px 5px #cbcbcb;
}

.video-gallery-header button a {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    text-decoration: none !important;
    color: #2b2b2b !important;
}

.video-modal-armon {
    margin-top: 10%;
    width: 100%;
}

@media (max-width: 1000px) {
    .video-container .image {
        /*filter: brightness(55%);*/
    }

    .video-gallery img {
        box-shadow: none;
    }

    .middle {
        top: 55%;
        opacity: 1 !important;
    }

    .video-gallery {
        padding: 0;
    }

    .video-modal-armon {
        width: 100%;
    }

    .video-gallery-header button {
        width: 100px;
        height: 35px;
        font-size: 12px !important;
    }
}

@media (min-width: 480px) and (max-width: 768px) {

    .video-gallery img {
        box-shadow: none;
    }

    .bVoXXg {
        margin-left: 0;
    }

    .dqhhZF {
        padding-left: 0;
    }
}

@media (max-width: 768px) {

    .video-container {
        height: 170px;
    }

    .video-wrapper {
        padding: 0 10px;
    }

    .video-gallery-header span {
        font-size: 24px;
        line-height: 29px;
    }

    .video-gallery-header {
        margin-bottom: 20px;
    }

}

@media (max-width: 590px) {

    .video-wrapper {
        justify-content: space-between;
    }

    .video-container {
        max-width: 156px;
        height: 86px;
    }

    .play-btn img {
        width: 32px;
        height: 32px;
    }
}

/* @media (max-width: 767px) {
    .video-gallery-header {
        height: auto;
        margin-bottom: 1rem;
    }

    .video-gallery-header button {
        display: none;
    }

    .video-gallery {
        width: 100%;
    }

    .video-gallery img {
        width: 100%;
        height: 200px;
        border-radius: 13px;
    }
} */

@media screen and (min-width: 320px) and (max-width: 375px) {
    .video-modal-armon {
        width: 100%;
        height: 250px;
    }

    .react-yt-pl {
        width: 300px !important;
        height: 250px;
    }

    .video-modal-yt {
        height: 200px;
    }

    .video-gallery-header {
        margin-top: 8px;
        padding-bottom: 25px !important;
        width: 100%;
    }

    .video-gallery-header {
        width: 100%;
        height: auto;
        margin-bottom: 0;
        margin-left: 0 !important;
    }
}
