.ofices-section {
    /* background: url(../../assets/img/чап.svg) 1% 3.1% repeat-y,
        url(../../assets/img/рост.svg) 99% 3.1% repeat-y; */
    padding: 100px 0 130px;
    background-size: 100px;
}


.notf__title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 75px;
    line-height: 91px;
    text-align: center;
    text-transform: uppercase;

    /* Black Color Armon */

    color: #080B11;
}

.page__not {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;

    /* Black Color Armon */

    color: #080B11;

}

.notf__text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;

    color: #000000;

}

.notf__btn button {
    outline: none !important;
    padding: 0 20px 0 20px;
    height: 40px;
    border: none;
    background: #0273BA;
    border-radius: 8px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.5px;

    /* White Color Armon */

    color: #FFFFFF;

}

@media screen and (min-width: 320px) and (max-width: 768px) {
    .notf__title {
        font-size: 50px;
    }

    .img__notf {
        max-width: 90%;
    }
}