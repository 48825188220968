.projects {
  position: relative;
  z-index: 1;
  max-width: 840px;
  margin: 40px auto;
}

.projects-wrapper {
  padding: 0 25px;
}

@media (max-width: 1024px) {
  .projects {
    max-width: 700px;
  }

  .projects-wrapper {
    /*padding: 0 15px;*/
  }
}

@media (max-width: 768px) {
  .projects {
    max-width: 600px;
  }
}

@media (max-width: 590px) {
  .projects {
    max-width: 400px;
  }

  .projects .projects-button {
    margin-top: 20px;
  }
}

.projects a {
  text-decoration: none !important;
}

.mobile-projects-cards {
  width: 100%;
  /* margin-left: 21px; */
  height: auto;
  display: flex;
  justify-content: space-between;
}

.mobile-projects-cards > a:not(:last-child) {
  margin-right: 40px;
}

.projects .carousel-container {
  height: 480px;
}


.projects-button {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 20px;
  color: #526ED3;
  border-radius: 4px;
  border: none;
  background: none;
  transition: all .5s;
  margin-left: auto;
  display: block;
  /*margin-top: 20px;*/
  margin-right: 10px;
  padding: 10px 30px;
}

.projects-button:hover {
  background: rgba(82, 110, 211, .1);
}

.projects-button a {
  color: #526ED3 !important;
}


.project-item {
  /*padding: 20px;*/
  overflow: hidden;
  height: 424px;
}

.projects-header {
  width: 100%;
  height: auto;
}

.projects-header .projects-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0 40px;
}

.under-test {
  width: 121px;
  height: 6px;
  background: #0273ba;
  border-radius: 2px;
}

.projects-header p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #0273BA;
  opacity: 0.48;
  margin-bottom: 8px;
}

.projects-header span {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 34px;
  line-height: 50px;
  letter-spacing: -0.01em;
  margin: 0;
  color: #080B11;

}

.projects-header button {
  display: block;
  float: right;
  width: 150px;
  height: 40px;
  background: #ffffff;
  border: 3px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 4px;
}

.projects-header button:hover {
  box-shadow: 0px 0px 10px 5px #cbcbcb;
}

.projects-header button a {
  text-decoration: none !important;
  color: #2b2b2b !important;
}

.project-card {
  max-width: 380px;
  height: 424px;
  background: #ffffff;
  /*box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);*/
  border-radius: 4px;
  overflow: hidden;
  transition: all .5s;
  /*border: 1px solid #EDEDED;*/
  display: flex;
  flex-direction: column;
}

.project-card:hover {
  box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.2);
}

.mobile-btn {
  display: none;
}

.projects .mobile-btn {
  margin-top: 20px;
}

.wrapper-card {
  display: flex;
  justify-content: center;
  /*height: 100%;*/
}

.project-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.project-info {
  padding: 20px;
  border: 1px solid #EDEDED;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 224px;
  box-sizing: border-box;
  flex-grow: 1;
}

.project-address {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin: 8px 0;

  /* Dark Color */

  color: #000000;

  opacity: 0.48;
}

.project-card h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -0.01em;
  color: #080B11;
  margin: 0;
}

.project-card .p-address {
  padding-top: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #2b2b2b;
}

.text__projects-tr {
  margin: 8px 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #080B11;
  opacity: 0.65;
}

.react-multiple-carousel__arrow--left {
  display: none;
}

.react-multiple-carousel__arrow--right {
  display: none;
}

@media (max-width: 1920px) {
  .react-multi-carousel-dot--active button {
    display: none;
  }

  .react-multi-carousel-dot button {
    display: none;
  }
}

@media (max-width: 1000px) {

  .mobile-projects-cards .project-card {
    /*margin: 0 auto;*/
  }

  .projects {
    padding: 0;
    margin: 40px auto;
  }

  .projects-header {
    z-index: 10;
  }

  .projects-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .projects-header button {
    width: 200px;
    height: 35px;
    font-size: 12px !important;
  }

  .wrapper-card {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none !important;
  }

  .mobile-projects-cards a {
    text-decoration: none !important;
  }
}

@media (max-width: 768px) {

  .projects {
    /*max-width: 510px;*/
    margin-top: 25px;
  }

  .react-multi-carousel-dot--active button {
    background: #3f3f3f;
  }

  .react-multi-carousel-dot button {
    display: inline-block;
    background: #c4c4c4;
    border: none;
    width: 16px;
    height: 16px;
  }

  .react-multi-carousel-dot--active button {
    background: #3f3f3f;
  }

  .projects-header {
    margin-bottom: 0px;
  }

  .projects-header button {
    display: none;
  }

  .projects-header span {
    font-size: 24px;
    line-height: 29px;
  }

  .projects .projects-inner {
    margin-bottom: 20px;
  }

  .mobile-projects-cards {
    width: 100%;
    margin-left: 0;
    height: auto;
    /*flex-wrap: wrap;*/
    justify-content: center;
  }

  .mobile-projects-cards a {
    margin-right: 0;
  }

  .project-card {
    /*width: 90% !important;*/
    /*margin-bottom: 25px !important;*/
    /*height: 376px;*/
  }

  .project-card img {
    height: 184px;
  }

  .project-info {
    padding: 10px 20px;
  }

  .project-info h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #080B11;
    margin: 8px 0 12px;
  }

  .text__projects-tr {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #080B11;

    opacity: 0.65;
  }
}

@media (max-width: 590px) {
  .mobile-projects-cards {
    flex-wrap: wrap;
  }

  .mobile-projects-cards a {
    margin-right: 0 !important;
  }

  .mobile-projects-cards a:last-of-type .wrapper-card {
    margin-bottom: 0;
  }

  .project-card {
    max-width: 100%;
    height: 376px;
  }

  .project-info {
    height: 192px;
  }



  .wrapper-card {
    margin-bottom: 40px;
  }
}

@media (max-width: 465px) {
  .projects-header {
    margin-left: 0;
  }
}