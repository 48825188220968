.paginator-active button:not(:disabled) {
  background: #0273BA;
  color: #fff;
}

.pagination-block {
  display: block;
  max-width: 840px !important;
  margin: 0 auto;
  padding: 0 20px;
}

@media (max-width: 840px) {
  .pagination-block {
    max-width: 390px !important;
    padding: 0 10px;
  }
}

.paginate {
  width: 100%;
  padding: 0;
}

.paginate li {
  list-style: none;
}

.paginate__navigate {
  width: 30px;
  height: 32px;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  border-radius: 3px;
}

.paginate__navigate {

}

.paginate__navigate img {
  width: 6px;
  height: 10px;
}

.paginate__btn {
  /*width: 37px;*/
  /*height: 37px;*/
  /*outline: none;*/
  /*border: none;*/
  /*background: #1679be;*/
  /*border-radius: 6px;*/
  /*font-family: Montserrat;*/
  /*font-style: normal;*/
  /*font-weight: 600;*/
  /*font-size: 25px;*/
  /*line-height: 30px;*/
  font-family: 'Manrope', sans-serif;
  width: 30px;
  height: 32px;
  background: none;
  border-radius: 3px;
  border: none;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 20px;
  color: #0273BA;
}

.paginate__btn img {
  padding: 0 0 3px;
}

@media screen and (min-width: 375px) and (max-width: 768px) {
  .paginator-active button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paginate li {
  }
}

/* @media screen and (min-width: 320px) and (max-width: 360px) {
  
} */
