.news__img {
    /*max-width: 464px;*/
    /*max-height: 385px;*/
    /*border-radius: 25px;*/
    margin-bottom: 40px;
}

.news__img img {
    object-fit: cover;
    /*max-width: 464px;*/
    border-radius: 4px;
    min-height: 385px;
    width: 100%;
}

.news-byId .news__title-text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 34px;
    line-height: 50px;
    letter-spacing: -0.01em;
    color: #0273BA;
    margin-bottom: 40px;
}

.news-byId .news__text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: #000000;
    margin-bottom: 40px;
}

.news__text-address {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #2B2B2B;
}

.news__text-address a {
    text-decoration: none;
    color: #2B2B2B !important;
}

.news-cards {
    display: flex;
}

.news__last .news-header {
    margin-bottom: 30px;
}

.news__title-text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.01em;
    color: #000000;
}

.news__last a {
    text-decoration: none !important;
}

.news__last .text__news-t p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #000000;
    opacity: 1;
}

.news__last .news-card {
    height: 224px;
}

@media (max-width: 1024px) {
    .news-byId .news__title-text {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .news-byId .news__text {
        font-size: 18px;
        margin-bottom: 20px !important;
    }
}

@media (max-width: 768px) {
    .news-byId .news__title-text {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .news-byId .news__text p {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 30px !important;
    }

    .news__last {
        display: none;
    }
}

@media (max-width: 640px) {
    .news-byId .news__title-text {
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 12px;
    }

    .news-byId .news__text p {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 15px;
    }

    .news__last {
        display: none;
    }
}