.navbar-brand svg {
    margin-top: -5px;
    padding: 0;
    margin: 5px;
    width: 150px !important;
    height: 50px !important;
}

/* .navbar-brand .logo-header-r {
    display: none;
} */

@media (max-width: 1920px) {
    .navbar-brand svg {
        margin-top: -5px;
        padding: 0;
        margin: 5px;
    }
}

/* @media (max-width: 1440px) {
    .navbar-brand svg {
        margin-top: -5px;
        padding: 0;
        margin: 5px;
    }
} */
@media (max-width: 1280px) {
    .navbar-brand svg {
        width: 130px !important;
        height: 55px !important;
        padding: 0;
        /* margin: 5px; */
    }
}

/* 
@media (max-width: 1024px) {
    .navbar-brand svg {
        width: 100px !important;
        height: 55px !important;
        margin-top: -6px !important;
        padding: 0;
        margin: 0px;
    }
} */

@media (max-width: 425px) {
    .navbar-brand svg {
        width: 100px !important;
        height: 55px !important;
        /* margin-top: 8px !important; */
        padding: 0;
        margin: 10px;
    }
}