.modal-lg {
  max-width: 600px !important;
  z-index: 999999 !important;
}

.modal-body {
  max-width: 460px;
  margin: 0 auto;
  padding: 40px 20px !important;
}

.text__modal {
  font-family: Montserrat;
  font-weight: 800;
  font-size: 48px;
  line-height: 70px;
  text-align: center;
  letter-spacing: -1px;
  color: #ffffff;
  margin-bottom: 0;
}

.text__modal-fdb {
  max-width: 421px;
  margin: 0 auto;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: #ffffff;
  margin: 4px auto 26px;
}

.modal-lg .modal-body {
  margin-top: -1.7rem !important;
}

.modal-lg .modal-header .close {
  opacity: 1 !important;
  padding: 0 !important;
  width: 30px !important;
  margin: 0 0 0 auto !important;
  height: 30px !important;
  border-radius: 50% !important;
  border: 2px solid #fff !important;
  color: #ffffff !important;
  font-weight: 100 !important;
}

.modal-header {
  z-index: 99 !important;
  display: flex !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  /* padding: 0 !important; */
  border-bottom: none !important;
  border-top-left-radius: none !important;
  border-top-right-radius: none !important;
}

.success {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 100;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
}

.alert__paginate {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
}

.modal .modal-dialog .modal-content {
  border-radius: 25px !important;
  background-color: #383d45 !important;
  outline: none !important;
}

.form-group {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 20px !important;
}

.form-group:last-of-type {
  margin-bottom: 0 !important;
}

.modal-lg label {
  display: flex !important;
  justify-content: center !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -1px;

  color: #ffffff;
}

.input__modal {
  border-radius: 4px;
  height: 56px !important;
  padding: 16px !important;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: rgba(27, 31, 59, 0.65);
  /*width: 80% !important;*/
}

.toast {
  max-width: 100% !important;
}

.toast-body {
  display: flex !important;
  justify-content: center !important;
}

.modal__button {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px !important;
  margin-bottom: 10px !important;
  flex-direction: column;
  margin-top: 40px;
}

.modal__btn {
  background: #0273ba !important;
  border-radius: 2px;
  height: 56px;
  width: 100%;
  border: none !important;
  transition: all 0.5s;
}

.modal__btn:hover {
  background: #00619e !important;
}

.modal__btn--close {
  background: none !important;
  border: 1px solid rgba(108, 134, 226, 0.32) !important;
  border-radius: 2px !important;
  color: #fff;
  margin-top: 20px;
}

.modal__btn--close:hover {
  border: 1px solid rgba(133, 160, 248, 0.32) !important;
  background: none !important;
}

.under__text-modal {
}

.under__text {
  width: 107px;
  height: 4px;
  background: #0273ba;
  border-radius: 2px;
  margin-bottom: 10px;
}

@media (max-width: 580px) {
  .modal-body {
    padding: 25px 20px !important;
  }

  .text__modal {
    font-size: 38px;
  }

  .text__modal-fdb {
    font-size: 16px;
  }

  .input__modal {
    height: 45px !important;
    font-size: 13px !important;
    border-radius: 2px !important;
  }

  .modal__button {
    margin-top: 20px !important;
  }

  .modal__btn {
    height: 45px !important;
    font-size: 13px !important;
  }
}
