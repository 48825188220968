.banner__item {
    max-width: 248px;
    width: 100%;
    margin: 20px auto;
    flex-shrink: 0;
    box-shadow: 0 8px 16px rgba(51, 51, 51, 0.2);
    transition: all .5s;
}

.banner__slider {
    width: 100%;
    justify-content: center;
}

.wrapper {
    /*width: 248px;*/
    width: 100%;
    /*height: 170px;*/
    border-radius: 4px;
    background-color: #0273ba;
    color: #fff;
    cursor: pointer;
    padding: 15px 18px;
    flex-grow: 1;
    flex-shrink: 0;
    transition: all .5s;
}

/*.wrapper-inner p {*/
/*    font-family: "Montserrat", sans-serif;*/
/*    font-size: 18px !important;*/
/*}*/

.wrapper-inner__title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #fff;
    margin: 8px 0 5px;
}

.wrapper-inner__text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, .65);
    margin: 0;
    white-space: nowrap;
}

.wrapper-inner__text::after {
    content: attr(data-text);
}

.wrapper:hover {
    box-shadow: 2px 9px 17px #0000006b;
}

.wrapper_orange {
    background-color: #f27405;
}

.wrapper_green {
    background-color: #267300;
}

.wrapper__img {
    width: 52px;
    margin-bottom: 8px;
}

.wrapper__img_height {
    width: 172px !important;
}

@media (max-width: 1024px) {
    .banner__item {
        max-width: 320px;
    }
}

@media (max-width: 768px) {
    .banner__item {
        /*max-width: 250px;*/
    }
}

@media (max-width: 580px) {

    .banner__item {
        max-width: 97%;
        margin: 20px auto;
    }

    .wrapper {
        padding: 16px;
        box-sizing: border-box;
    }

    .wrapper__img {
        width: 35px;
        margin: 0;
    }

    .wrapper-inner__title {
        font-size: 17px;
        line-height: 23px;
    }

    .wrapper-inner__text {
        font-size: 14px;
        line-height: 18px;
    }

    .wrapper-inner__text::after {
        content: attr(data-mobile-text);
    }
}

@media (max-width: 590px) {

    .banner__item {
        /*max-width: 150px;*/
        margin: 20px auto;
    }

    .wrapper {
        padding: 8px;
        box-sizing: border-box;
    }

    .wrapper__img {
        width: 26px;
        margin: 0;
    }

    .wrapper-inner__title {
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
    }

    .wrapper-inner__text {
        font-size: 12px;
        line-height: 15px;
    }

    .wrapper-inner__text::after {
        content: attr(data-mobile-text);
    }
}