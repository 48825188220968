.main-slider {
    /*max-height: 100vh;*/
    max-width: 1920px;
    margin: 0 auto;
}

.main-slider .carousel-inner {

}

.slider-link {
    pointer-events: none;
}

.main-slider .carousel-caption {
    /*height: 180px;*/
    text-align: center;
    height: 150px;
    display: flex;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    /*left: 50%;*/
    left: 0;
    width: 100%;
}

.main-slider-button-link {
    display: block;
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
}

.main-slider-button-link:hover {
    text-decoration: none;
}

.main-slider-button {
    display: block;
    padding: 20px 30px !important;
    margin: 0 auto;
    border: 2px solid #fff !important;
    margin-top: 40px;
}

.carousel-item {
    background-size: 90% !important;
    background-repeat: no-repeat !important;
    background-position: right !important;
    max-height: 1100px;
    height: 100vh;
    min-height: 800px;
}

/*.carousel-caption {*/
/*    width: 100% !important;*/
/*    right: 0 !important;*/
/*    position: absolute !important;*/
/*    top: 0 !important;*/
/*    left: 0 !important;*/
/*    !* overflow: auto !important; *!*/
/*    overflow-x: hidden !important;*/
/*}*/

.slider_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* loader */

/* end loader */

.main-slider-type {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 5px;
    pointer-events: none;
}

.main-slider-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 48px;
    line-height: 70px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    pointer-events: none;
}

@media (min-width: 1024px) {
    .main-slider-title {
        font-size: calc(48px + (60 - 48) * ((100vw - 1024px) / (1920 - 1024)));
        line-height: 1.2;
    }

    .main-slider-type {
        font-size: calc(10px + (24 - 10) * ((100vw - 1024px) / (1920 - 1024)));
        line-height: 1.2;
    }

    .main-slider-button {
        font-size: calc(10px + (24 - 10) * ((100vw - 1024px) / (1920 - 1024))) !important;
    }

    .main-slider-button {
        /*font-size: calc(13px + (24 - 13) * ((100vw - 1024px) / (1920 - 1024)));*/
        /*line-height: 1.2;*/
    }
}

@media (min-width: 1920px) {
    .main-slider-title {
        font-size: 60px
    }

    .main-slider-type {
        font-size: 24px
    }

    .main-slider-button {
        font-size: 24px !important;
    }
}

.main-slider-button {
    display: block;
    background: none;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 0;
    /*padding: 20px;*/
    padding: 6px 12px !important;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 154% */
}

.carousel__img {
    float: right;
    max-width: 90%;
    object-fit: cover;
}

.slide__btn button a {
    text-decoration: none !important;
    color: #ffffff !important;
}

.slide__btn button {
    border: none;
    height: 40px;
    padding: 0 15px 0 15px;
    background: #0273ba;
    color: #ffffff;
    margin-right: 15px;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.5px;
    outline: none !important;
}


@media (max-width: 1920px) {
    .main-slider-button {
        /*font-size: 20px !important;*/

    }
}

@media (max-width: 1500px) {
    .main-slider-button {
        /*margin-top: 20px;*/
        /*font-size: 18px;*/
    }

    .carousel-item {
        min-height: 600px;
    }
}

.mob__btn-slider button {
    display: none;
}

.carousel-indicators .active {
    opacity: 1;
    background-color: #0273ba !important;
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 25px !important;
    height: 25px !important;
    border-radius: 50% !important;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff !important;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 1 !important;
    transition: opacity 0.6s ease;
}

.carousel-control-prev-icon {
    background-image: url("../../assets/icon-slider/w2.svg") !important;
}

.carousel-control-next-icon {
    background-image: url("../../assets/icon-slider/w1.svg") !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 60px !important;
    height: 60px !important;
}

.carousel-control-next,
.carousel-control-prev {
    opacity: 1 !important;
}

.carousel-control-next,
.carousel-control-prev {
    width: 5% !important;
}

@media (max-width: 1920px) {
    /*.carousel-item {*/
    /*    background-size: 100% !important;*/
    /*    background-position: right !important;*/
    /*    background-repeat: no-repeat !important;*/
    /*    background-position: center center !important;*/
    /*    background-attachment: fixed !important;*/
    /*    -webkit-background-size: cover !important;*/
    /*    -moz-background-size: cover !important;*/
    /*    -o-background-size: cover !important;*/
    /*    background-size: cover !important;*/
    /*    width: 100% !important;*/
    /*    !*height: 900px !important;*!*/
    /*}*/

    .carousel-slider {

    }
}

@media (min-height: 1440px) {
    .main-slider .carousel-item {
        /*max-height: 700px;*/
        /*max-height: 100vh;*/
    }
}

@media (max-width: 1440px) {
    .main-slider-button {
        margin-top: 20px;
    }
}

@media (max-width: 640px) {
    .carousel-item {
        min-height: 400px;
        max-height: 500px;
    }

    .slider-link {
        pointer-events: all;
    }

    .main-slider-button {
        display: none;
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .carousel-item {
        min-height: 500px;
        max-height: 700px;
    }
}

@media (min-width: 1200px) {

    .main-slider .carousel-item {
        /*max-height: 700px;*/
        /*max-height: 90vh;*/
    }
}

@media (max-width: 860px) {
    .main-slider-title {
        font-size: 40px;
        line-height: 1.2;
    }

    .main-slider-type {
        font-size: 8px;
        line-height: 1.2;
    }

    .main-slider-button {
        font-size: 10px;
        padding: 2px 10px;
    }
}

@media (max-width: 768px) {
    .carousel-item {
        height: 500px;
    }
}

@media (max-width: 480px) {
    .main-slider-title {
        font-size: 30px;
    }

    .main-slider-type {
        font-size: 7px;
    }

    .main-slider-button {
        font-size: 10px;
    }
}

@media (max-width: 375px) {
    .carousel-item {
        height: 400px;
    }

    .my__auto h3 {
        font-size: 23px;
    }
}
